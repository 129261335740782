import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Freight Management</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Freight Management</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Blog Details Area */}
      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img src="assets/img/Freight Management.jpg" alt="image" />
                </div>
                <div className="article-content">
                  <div className="entry-meta"></div>
                  <h3>Freight Management</h3>
                  <p>
                    No matter the nature of your cargo or its destination, we
                    offer a perfect blend of reliability, security, and speed.
                    Our comprehensive range of supplementary services, variable
                    transit times, and flexible route choices ensure a
                    streamlined shipping experience.
                  </p>
                  <p>
                    Our local team members work closely with your suppliers to
                    ensure the availability of your goods and to arrange
                    efficient pickup and delivery, keeping your supply chain
                    operating smoothly.
                  </p>
                  <p>
                    Our Freight Management services in Singapore go beyond
                    simply delivering your cargo to the right place at the right
                    time; they also involve careful handling at each stage of
                    the journey to ensure safe and punctual delivery. We
                    meticulously oversee every aspect of your shipment. Our team
                    of freight experts will recommend the most cost-effective
                    route to guarantee that your cargo arrives at its
                    destination, no matter where that may be, in excellent
                    condition and on schedule. Here is a list of the freight
                    management services we can provide:
                  </p>
                  <p>
                    <strong>OCEAN FREIGHT</strong>
                  </p>
                  <ul>
                    <li>FCL (Full Container Load)</li>
                    <li>LCL (Less than Container Load)</li>
                    <li>NVOCC (Non-Vessel Operating Common Carrier)</li>
                    <li>Break bulk cargo</li>
                    <li>Ro-Ro cargo (Roll-on/Roll-off)</li>
                    <li>Flexitanks</li>
                    <li>Specialized Equipment</li>
                    <li>Perishable (Refrigerated and Frozen)</li>
                    <li>Dangerous goods</li>
                  </ul>
                  <p>
                    <strong>AIR FREIGHT</strong>
                  </p>
                  <ul>
                    <li>IATA licensed</li>
                    <li>Consolidations</li>
                    <li>Charters</li>
                    <li>Perishable & DG (Dangerous Goods)</li>
                    <li>Critical shipments</li>
                    <li>Services available 24/7</li>
                    <li>AOG (Aircraft on Ground)</li>
                    <li>Hand carriers</li>
                  </ul>
                  <p>
                    <strong>ROAD TRANSPORT</strong>
                  </p>
                  <ul>
                    <li>International & National road transport</li>
                    <li>
                      Full Truck Loads (FTL), Partial Truck Loads (LTL), and hot
                      shot/dedicated services
                    </li>
                    <li>Groupage</li>
                    <li>Container Drayage</li>
                    <li>Specialized Equipment</li>
                  </ul>
                  <p>
                    <strong>RAIL TRANSPORT</strong>
                  </p>
                  <ul>
                    <li>
                      FCL & LCL – faster than ocean and more economical than air
                    </li>
                  </ul>
                  <p>
                    <strong>MULTIMODAL TRANSPORT</strong>
                  </p>
                  <ul>
                    <li>Rail – Road – Ocean</li>
                    <li>Sea – Air</li>
                    <li>Groupage</li>
                    <li>Container Drayage</li>
                    <li>Specialized Equipment</li>
                  </ul>
                  <p>
                    <strong>CROSS TRADE TRANSPORT</strong>
                  </p>
                  <p>
                    Management of global triangular operations for various types
                    of transport.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_categories">
                  <h3 className="widget-title">Other Services</h3>
                  <ul>
                    <li>
                      <Link to="/Freight">Freight Management </Link>
                    </li>

                    <li>
                      <Link to="/Supply">
                        International Supply Chain Management{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/Contract">Contract Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Project">Project Cargo Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Global">Global Trade Management </Link>
                    </li>

                    <li>
                      <Link to="/Custom">Custom Clearance </Link>
                    </li>
                  </ul>
                </section>
                <section className="widget widget_recent_comments">
                  <h3 className="widget-title">Cargo Managed</h3>
                  <ul>
                    <li>
                      <Link to="/Perishables">Perishables </Link>
                    </li>
                    <li>
                      <Link to="/General">General Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Dangerous">Dangerous Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Heavy">Heavy Lift </Link>
                    </li>
                    <li>
                      <Link to="/Trade">Trade Shows & Exhibitions </Link>
                    </li>
                  </ul>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Details Area */}
      <Footer />
    </>
  );
}
