import { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import $ from "jquery";
import { Outlet, Link } from "react-router-dom";
import { companyname } from "../components/Companyinfo";

export default function Getquote() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");
  const [uorigin, setUorigin] = useState("");
  const [udestination, setUdestination] = useState("");
  const [ugoods, setUgoods] = useState("");
  const [umeasurements, setUmeasurements] = useState("");
  const [ulength, setulength] = useState("");
  const [uheight, setUheight] = useState("");
  const [uwidth, setUwidth] = useState("");
  const [uweight, setUweight] = useState("");
  const [udate, setUdate] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };
  const handleuoriginChange = (e) => {
    setUorigin(e.target.value);
  };

  const handleudestinationChange = (e) => {
    setUdestination(e.target.value);
  };
  const handlugoodsChange = (e) => {
    setUgoods(e.target.value);
  };
  const handleumeasurementsChange = (e) => {
    setUmeasurements(e.target.value);
  };
  const handleulengthChange = (e) => {
    setulength(e.target.value);
  };
  const handleuheightChange = (e) => {
    setUheight(e.target.value);
  };
  const handleuwidthChange = (e) => {
    setUwidth(e.target.value);
  };
  const handleuweightChange = (e) => {
    setUweight(e.target.value);
  };
  const handleudateChange = (e) => {
    setUdate(e.target.value);
  };
  const handlesubjectChange = (e) => {
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };

  return (
    <>
      <Header />

      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Request For Quote</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Request For Quote</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Contact Area */}
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Get Quote</span>
            <h2>Request For Quote</h2>
            <p>
            Join our satisfied customers who have benefitted from our tailored freight solutions. Fill out the quote form and experience the difference.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="contact-form">
              <form className="contact-page__form contact-form-validated"
          action="/php/thankyou-getquote.php"
          method="post"
          onSubmit={(event) => handleSubmit(event)}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="contact-page__form-input-box">
                <input
                  className="form-control"
                  value={uname}
                  onChange={(event) => handleUnameChange(event)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "6px 0",
                  }}
                  type="text"
                  name="uname"
                  id="uname"
                  placeholder="Enter your Fullname"
                  required="Please Enter the Fullname"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-page__form-input-box">
                <input
                  className="form-control"
                  value={uemail}
                  onChange={(event) => handleUemailChange(event)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "6px 0",
                  }}
                  type="email"
                  name="uemail"
                  id="uemail"
                  placeholder="Enter Your Email"
                  required="Please Enter Your Email"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-page__form-input-box">
                <input
                  className="form-control"
                  value={uphone}
                  onChange={(event) => handleUphoneChange(event)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "6px 0",
                  }}
                  type="text"
                  name="uphone"
                  id="uphone"
                  placeholder="Enter Phone Number"
                  required="Please Enter Phone Number"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="contact-page__form-input-box">
                <input
                  className="form-control"
                  value={subject}
                  onChange={(event) => handlesubjectChange(event)}
                  type="text"
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "6px 0",
                  }}
                  id="subject"
                  name="subject"
                  placeholder="Enter subject"
                />
              </div>
            </div>
            <div className="col-12" style={{ padding: "10px" }}>
              <select
                class="filter-option-inner-inner"
                name="uservice"
                id="uservice"
                value={uservice}
                onChange={(event) => handleuserviceChange(event)}
                style={{
                  outline: "none",
                  color: "#333",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  letterSpacing: "2px",
                  width: "100%",
                  border: "1px solid #ddd",
                  textAlign: "left",
                  background: "#F9FAFF",
                }}
              >
                <option selected>Service Required</option>
                <option value="Air Freight Forwarding">
                  Air Freight Forwarding
                </option>
                <option value="Ocean Freight Forwarding">
                  Ocean Freight Forwarding
                </option>
                <option value="Project Cargo">Project Cargo</option>
              </select>
            </div>
            <div className="contact-page__form-input-box col-lg-4">
              <input
                className="form-control"
                value={uorigin}
                onChange={(event) => handleuoriginChange(event)}
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "6px 0",
                }}
                type="text"
                name="uorigin"
                id="uorigin"
                placeholder="Origin City"
              />
            </div>
            <div className="contact-page__form-input-box col-lg-4">
              <input
                className="form-control"
                value={udestination}
                onChange={(event) => handleudestinationChange(event)}
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "6px 0",
                }}
                type="text"
                name="udestination"
                id="udestination"
                placeholder="Destination City"
              />
            </div>
            <div className="contact-page__form-input-box col-lg-4">
              <input
                className="form-control"
                value={ugoods}
                onChange={(event) => handlugoodsChange(event)}
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "6px 0",
                }}
                type="text"
                name="ugoods"
                id="ugoods"
                placeholder="Types Of Goods"
              />
            </div>
            <div className="col-12" style={{ padding: "10px" }}>
              <select
                className="form-control"
                value={umeasurements}
                onChange={(event) => handleumeasurementsChange(event)}
                name="umeasurements"
                id="umeasurements"
                style={{
                  outline: "none",
                  color: "#333",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  letterSpacing: "2px",
                  width: "100%",
                  border: "1px solid #ddd",
                  textAlign: "left",
                  background: "#F9FAFF",
                }}
              >
                <option selected>Measurements Below</option>
                <option value="Metric(Kgs/cms)">
                  Metric(Kgs/cms)
                </option>
                <option value="Imperial(lbs/in)">
                  Imperial(lbs/in)
                </option>
              </select>
            </div>
            <div className="contact-page__form-input-box col-lg-6">
              <input
                className="form-control"
                value={ulength}
                onChange={(event) => handleulengthChange(event)}
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "6px 0",
                }}
                type="text"
                name="ulength"
                id="ulength"
                placeholder="Length"
              />
            </div>
            <div className="contact-page__form-input-box col-lg-6">
              <input
                className="form-control"
                value={uheight}
                onChange={(event) => handleuheightChange(event)}
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "6px 0",
                }}
                type="text"
                name="uheight"
                id="uheight"
                placeholder="Height"
              />
            </div>
            <div className="contact-page__form-input-box col-lg-6">
              <input
                className="form-control"
                value={uwidth}
                onChange={(event) => handleuwidthChange(event)}
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "6px 0",
                }}
                type="text"
                name="uwidth"
                id="uwidth"
                placeholder="Width"
              />
            </div>
            <div className="contact-page__form-input-box col-lg-6">
              <input
                className="form-control"
                value={uweight}
                onChange={(event) => handleuweightChange(event)}
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "6px 0",
                }}
                type="text"
                name="uweight"
                id="uweight"
                placeholder="weight"
              />
            </div>
            <div className="col-12 " style={{ padding: "10px" }}>
              <label>Date Required at destination</label>
              <div
                className="date"
                id="date"
                data-target-input="nearest"
              >
                <input
                  className="form-control"
                  value={udate}
                  onChange={(event) => handleudateChange(event)}
                  type="date"
                  name="udate"
                  id="udate"
                  placeholder="Date required at destination"
                  style={{ height: "55px", width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="contact-page__form-input-box">
                <textarea
                  className="form-control"
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "6px 0",
                  }}
                  value={umessage}
                  onChange={(event) => handlemessageChange(event)}
                  name="umessage"
                  id="umessage"
                  placeholder="Write Message"
                  defaultValue={""}
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              name="submitquote"
              defaultValue="SEND"
              className="default-btn"
            >
              Send Request
            </button>
          </div>
          <br />
        </form>
        <h5 style={{color:"red"}}>{result}</h5>
              </div>
            </div>

          </div>

        </div>
        <div className="bg-map">
          <img src="assets/img/bg-map.png" alt="image" />
        </div>
      </section>
      
      <Footer />
    </>
  );
}
