import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Contact</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Contact Area */}
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Message Us</span>
            <h2>Global Reach, Local Touch</h2>
            <p>
             Discover the perfect blend of global reach and local support by contacting us through our form.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="contact-form">
                <form
                  id="contactForm"
                  className="contact-page__form contact-form-validated"
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="default-btn"
                        type="submit"
                        defaultValue=""
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.798563596697!2d103.85587522412169!3d1.2954439117500864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1974c7afb233%3A0x5579dad594aa077f!2sSuntec%20Tower%201!5e0!3m2!1sen!2sin!4v1695367428699!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="contact-info">
            <div className="contact-info-content">
              <h3>Contact us by Email Address</h3>
              <h2>
                
                <a href="mailto:pearo@gmail.com">{companyemail}</a>
              </h2>
           
            </div>
          </div>
        </div>
        <div className="bg-map">
          <img src="assets/img/bg-map.png" alt="image" />
        </div>
      </section>
      <Footer />
    </>
  );
}
