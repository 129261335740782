import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Trade Shows & Exhibitions</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Trade Shows & Exhibitions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Blog Details Area */}
      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img
                    src="assets/img/Trade Shows & Exhibitions.jpg"
                    alt="image"
                  />
                </div>
                <div className="article-content">
                  <div className="entry-meta"></div>
                  <h3>Trade Shows & Exhibitions</h3>
                  <p>
                    {companyname} Company places the utmost importance on delivering
                    personalized and tailored services. Our experts consistently
                    demonstrate their competence in handling a wide range of
                    cargo types. We specialize in offering customized
                    transportation solutions for time-sensitive trade shows and
                    event materials. Our highly skilled and dedicated team
                    collaborates closely with our superior accessibility to
                    ensure that we can fulfill all your requirements. In Hong
                    Kong, the trade show, fair, and exhibition industry is a
                    prominent platform for professionals. {companyname} recognizes
                    that the information associated with your exhibition and
                    fair is time-sensitive. When you choose our logistics
                    services for your special events, we will provide you with a
                    comprehensive cost estimate, including specific details
                    about the handling of your intercepted cargo.
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_categories">
                  <h3 className="widget-title">Other Services</h3>
                  <ul>
                    <li>
                      <Link to="/Freight">Freight Management </Link>
                    </li>

                    <li>
                      <Link to="/Supply">
                        International Supply Chain Management{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/Contract">Contract Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Project">Project Cargo Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Global">Global Trade Management </Link>
                    </li>

                    <li>
                      <Link to="/Custom">Custom Clearance </Link>
                    </li>
                  </ul>
                </section>
                <section className="widget widget_recent_comments">
                  <h3 className="widget-title">Cargo Managed</h3>
                  <ul>
                    <li>
                      <Link to="/Perishables">Perishables </Link>
                    </li>
                    <li>
                      <Link to="/General">General Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Dangerous">Dangerous Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Heavy">Heavy Lift </Link>
                    </li>
                    <li>
                      <Link to="/Trade">Trade Shows & Exhibitions </Link>
                    </li>
                  </ul>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Details Area */}
      <Footer />
    </>
  );
}
