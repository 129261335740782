import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Mining</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Mining</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Mining</h3>
                  <p>
                    Our proficient team, boasting extensive experience in the
                    mining sector, empowers us to deliver comprehensive,
                    high-quality solutions while upholding stringent safety
                    standards. We earn our clients' confidence by providing
                    clear-cut resolutions to complex logistical challenges.
                  </p>

                  <p>
                    Our proficiency in mining operations and projects has
                    endowed us with a profound understanding of our clients'
                    requirements. This insight has also instilled within our
                    team a sense of urgency and precision essential for meeting
                    the industry's exacting benchmarks:
                  </p>

                  <ul>
                    <li>Stringent standards for quality and safety.</li>
                    <li>
                      Pioneering innovations to enhance supply chain efficiency.
                    </li>
                    <li>
                      Adherence to environmental, social, and legal norms.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Mining.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
