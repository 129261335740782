import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Retail</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Retail</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Retail</h3>
                  <p>
                    Modern retail stores driven by demand require reliable,
                    efficient, and agile supply chains. We understand the
                    importance of a seamless journey from the manufacturer's
                    door to the port.
                  </p>

                  <p>
                    We work closely with your vendors to oversee production,
                    ensure timely availability, and facilitate a swift and
                    efficient turnaround. Our collaboration includes the
                    creation and monitoring of key performance indicators,
                    leading to ongoing value generation in partnership with our
                    clients.
                  </p>

                  <p>
                    We aim to be your single point of contact, providing
                    comprehensive support, including on-site assistance, to meet
                    all your needs.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Retail.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
