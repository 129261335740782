import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />{" "}
      <div className="home-area home-slides-two  w3-banner jarallax">
        <video autoPlay loop muted>
          <source src="assets/img/video.mp4"></source>
        </video>
      </div>
      <section className="about-area ptb-100 bg-f8f8f8">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img
                  src="assets/img/About1.jpg"
                  style={{ height: "300px", width: "360px" }}
                  alt="image"
                />
                <img
                  src="assets/img/About2.jpg"
                  style={{ height: "300px", width: "360px" }}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <span>About Us</span>
                <h2>Wherever It Needs to Go, We Get It There.</h2>
                <p>
                  {companyname} stands as an environmentally conscious global
                  freight forwarding company. Our central focus lies not just in
                  meeting but, more importantly, surpassing expectations, which
                  forms the bedrock of our corporate strategy. We handle the
                  demanding logistics tasks, allowing our clients to relax and
                  unwind.
                </p>
                <p>
                  We firmly hold the belief that top-tier freight services
                  should be accessible to everyone. With our dedicated and
                  skilled team, we are wholeheartedly committed to delivering
                  unparalleled service in the industry. We work closely with our
                  valued clients to ensure their freight operations progress
                  seamlessly and efficiently, all while enhancing their
                  profitability.
                </p>
              </div>
            </div>
          </div>
          <div className="about-inner-area">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="about-text-box">
                  <h3>Our Mission</h3>
                  <p>
                    Our mission is to deliver superior maritime services,
                    streamline logistics processes, and operate modern port
                    terminals while ensuring the highest standards of safety and
                    security, ultimately becoming the go-to choice for our
                    valued clients.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3 col-sm-6">
                <div className="about-text-box">
                  <h3>Our Vision</h3>
                  <p>
                    Our vision is to establish ourselves as a market-leading
                    organization known for excellence in maritime services,
                    logistics solutions, and port operations on a global scale.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End About Area */}
      <section className="services-area ptb-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Freight Services</span>
            <h2>Explore our Freight Solutions</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img src="assets/img/Freight Management.jpg" alt="image" />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Freight">Freight Management</Link>
                  </h3>
                  <p> No matter the nature of your cargo or its destination, we
                    offer a perfect blend of reliability, security, and speed...</p>
                  <Link to="/Freight" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img
                    src="assets/img/International Supply Chain Management.jpg"
                    alt="image"
                  />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Supply">
                      International Supply Chain Management
                    </Link>
                  </h3>
                  <p> By blending state-of-the-art technology, well-established
                    processes, and both local and international expertise, we..</p>
                  <Link to="/Supply" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img src="assets/img/Contract Logistics.jpg" alt="image" />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Contract">Contract Logistics</Link>
                  </h3>
                  <p> We enhance the entire supply chain by integrating all
                    processes, including storage, transportation, and</p>
                  <Link to="/Contract" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img
                    src="assets/img/Project Cargo Logistics.jpg"
                    alt="image"
                  />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Project">Project Cargo Logistics</Link>
                  </h3>
                  <p> Our specialized division, Singapore Project Cargo, manages
                    logistics for large and heavy lift project cargo required...</p>
                  <Link to="/Project" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img
                    src="assets/img/Global Trade Management.jpg"
                    alt="image"
                  />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Global">Global Trade Management</Link>
                  </h3>
                  <p> Global trade presents a challenge to businesses aiming to
                    thrive in the global economy. With the assistance of ..</p>
                  <Link to="/Global" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img src="assets/img/Custom Clearance.jpg" alt="image" />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Custom">Custom Clearance</Link>
                  </h3>
                  <p>While importers ultimately bear the responsibility for
                    submissions to customs authorities worldwide, our trusted</p>
                  <Link to="/Custom" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Services Area */}
      {/* Start Why Choose Us Area */}
      <section className="why-choose-us-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div
                className="why-choose-us-slides "
                style={{ background: 'url("../assets/img/banner.jpg")' }}
              >
                <div className="why-choose-us-image bg1">
                  <img src="assets/img/why-choose-img1.jpg" alt="image" />
                </div>
                <div className="why-choose-us-image bg2">
                  <img src="assets/img/why-choose-img2.jpg" alt="image" />
                </div>
                <div className="why-choose-us-image bg3">
                  <img src="assets/img/why-choose-img3.jpg" alt="image" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="why-choose-us-content">
                <div className="content">
                  <div className="title">
                    <span className="sub-title">Why Us</span>
                    <h2>Why Choose Us</h2>
                    <p>
                      We strive to provide exceptional maritime services,
                      comprehensive logistics solutions, and state-of-the-art
                      port terminals. Our commitment to continuous improvement
                      and dedication to operating in a secure environment aim to
                      make us the preferred choice for our clients.
                    </p>
                  </div>
                  <ul className="features-list">
                    <li>
                      
                      <span>GREAT SUPPORT</span>
                      Experience exceptional customer service with a dedicated
                      Personal Singapore Consultant, ensuring outstanding
                      support from our team.
                    </li>
                    <li>
                     
                      <span>ONE STOP PROVIDER</span>
                      We serve as a comprehensive one-stop solution, offering a
                      wide array of services aimed at delivering top-tier
                      freight solutions.
                    </li>
                    <li>
                    
                      <span>COMPETITIVE PRICING</span>
                      Benefit from competitive pricing as one of the largest
                      global freight forwarding companies, allowing us to pass
                      on cost savings to our valued customers.
                    </li>
                    <li>
                     
                      <span>TRANSSHIPMENT</span>
                      Transshipment offers flexible and cost-effective shipping
                      routes, providing advantages to shippers (consignors).
                    </li>
                    <li>
                     
                      <span>CONSOLIDATED FREIGHT</span>
                      Consolidation shipping services are ideal for businesses
                      or individuals with smaller cargo quantities, helping to
                      reduce costs by paying only for the space utilized.
                    </li>
                    <li>
                    
                      <span>PICK AND PACK WAREHOUSING</span>
                      Pick and pack warehousing involves selecting products from
                      a warehouse based on customer orders and preparing them
                      for delivery as a crucial step in the shipping process.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="team-area ptb-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Our Industries</span>
            <h2>Explore Our Industry Sectors</h2>
           
          </div>
          <div className="team-slides row ">
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Automotive.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Automotive" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Automotive</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Fashion.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Fashion" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Fashion</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Mining.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Mining" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Mining</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Reefer.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Reefer" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Reefer</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Chemical Industry.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Chemical" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Chemical Industry</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Food.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Food" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Food</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Oil And Gas.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Oil" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Oil And Gas</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Renewable Energy.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Renewable" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Renewable Energy</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Construction.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Construction" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Construction</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Furniture.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Furniture" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Furniture</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Pharma & Healthcare.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Pharma" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Pharma & Healthcare</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Retail.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Retail" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Retail</h3>
                <span>Industries</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ctr-area">
        <div className="container">
          <div className="ctr-content" style={{maxWidth:"765px"}}>
            <h2>Excellence in Every Shipment</h2>
            <Link to="/Getquote" className="default-btn">
              Get a Quote <i className="flaticon-right-chevron" />
              <span />
            </Link>
          </div>
          <div className="ctr-image">
            <img src="assets/img/About2.jpg" alt="image" />
          </div>
          <div className="shape">
            <img src="assets/img/bg-dot3.png" alt="image" />
          </div>
        </div>
      </section>
      {/* End CTR Area */}

      {/* Start Quote Area */}
      <section className="quote-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="quote-content">
                <h2>Get in Touch with us</h2>
                <p>
                Discover the perfect blend of global reach and local support by contacting us through our form.
                </p>
                <div className="image">
                  <img src="assets/img/contact.png" alt="image" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="tab quote-list-tab">
               
                <div className="tab_content">
                  <div className="tabs_item">
                    <p>Our experts will reply you with a quote very soon</p>
                    <form
                  id="contactForm"
                  className="contact-page__form contact-form-validated"
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="default-btn"
                        type="submit"
                        defaultValue=""
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Services</span>
            <h2>CARGO MANAGED</h2>
            <p></p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/Perishables">
                    <img src="assets/img/Perishables.jpg" alt="image" />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/Perishables">Perishables</Link>
                  </h3>
                  <p>Handling perishable goods requires meticulous attention to
                    detail, in-depth product knowledge, and efficient..</p>
                  <Link to="/Perishables" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/General">
                    <img src="assets/img/General Cargo.jpg" alt="image" />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/General">General Cargo</Link>
                  </h3>
                  <p> {companyname} specializes in the management of
                    third/cross-country logistics. </p>
                  <Link to="/General" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/Dangerous">
                    <img src="assets/img/Dangerous Cargo.jpg" alt="image" />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/Dangerous">Dangerous Cargo</Link>
                  </h3>
                  <p>Our firm places an unwavering emphasis on safety. We
                    meticulously review all booking details, and when deemed..</p>
                  <Link to="/Dangerous" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 offset-md-3 offset-lg-0"></div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/Heavy">
                    <img src="assets/img/Heavy Lift.jpg" alt="image" />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/Heavy">Heavy Lift</Link>
                  </h3>
                  <p> {companyname} Company places the utmost importance on
                    delivering personalized and tailored services.</p>
                  <Link to="/Heavy" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/Trade">
                    <img
                      src="assets/img/Trade Shows & Exhibitions.jpg"
                      alt="image"
                    />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/Trade">Trade Shows & Exhibitions</Link>
                  </h3>
                  <p> {companyname} Company places the utmost importance on delivering
                    personalized and tailored services.</p>
                  <Link to="/Trade" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
