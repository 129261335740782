import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Careers() {
  const [fullname, setFullname] = useState("");
  const [emailid, setEmailid] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [position, setPosition] = useState("");
  const [coverletter, setCoverletter] = useState("");
  const [attachfile, setAttachfile] = useState("");
  const [result, setResult] = useState("");

  const handlefullnameChange = (e) => {
    setFullname(e.target.value);
  };
  const handleemailidChange = (e) => {
    setEmailid(e.target.value);
  };
  const handlephoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handlequalificationChange = (e) => {
    setQualification(e.target.value);
  };
  const handlepositionChange = (e) => {
    setPosition(e.target.value);
  };
  const handlecoverletterChange = (e) => {
    setCoverletter(e.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    console.log(selectedFile);

    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Careers</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Careers</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Contact Area */}
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Careers</span>
            <h2>Opportunities At {companyname}</h2>
            <p>
            If you're ready to take your career to the next level, fill out our careers form today and embark on a journey of growth, learning, and opportunity.


            </p>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="contact-form">
                <form
                  action="/php/thankyou-careers.php"
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row p-2">
                    <div className="col-md-4">
                      <div className="contact-page__form-input-box">
                        <input
                          onChange={(event) => handlefullnameChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          value={fullname}
                          id="fullname"
                          name="fullname"
                          placeholder="Your Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="contact-page__form-input-box">
                        <input
                          onChange={(event) => handleemailidChange(event)}
                          type="email"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          value={emailid}
                          id="emailid"
                          name="emailid"
                          placeholder="Your Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="contact-page__form-input-box">
                        <input
                          onChange={(event) => handlephoneChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          value={phone}
                          id="handlephoneChange"
                          name="phone"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                  </div>
                  <label>Highest Qualification</label>
                  <div className="col-12 p-2">
                    <div className="contact-page__form-input-box">
                      <input
                        onChange={(event) => handlequalificationChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        value={qualification}
                        id="qualification"
                        name="qualification"
                        placeholder="Qualification"
                        required
                      />
                    </div>
                  </div>
                  <label>Position Applied for</label>
                  <div className="col-12 p-2">
                    <div className="contact-page__form-input-box">
                      <input
                        onChange={(event) => handlepositionChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        value={position}
                        id="position"
                        name="position"
                        placeholder="position"
                        required
                      />
                    </div>
                  </div>
                  <label>Add cover letter</label>
                  <div className="col-12 p-2">
                    <div className="contact-page__form-input-box">
                      <textarea
                        onChange={(event) => handlecoverletterChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        placeholder="Leave a message here"
                        value={coverletter}
                        id="coverletter"
                        name="coverletter"
                        required
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      name="submitcareers"
                      defaultValue="SEND"
                      className="default-btn"
                    >
                      Apply
                    </button>
                  </div>
                  <br />
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-map">
          <img src="assets/img/bg-map.png" alt="image" />
        </div>
      </section>

      <Footer />
    </>
  );
}
