import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Services</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="services-area ptb-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Freight Services</span>
            <h2>Explore our Freight Solutions</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img src="assets/img/Freight Management.jpg" alt="image" />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Freight">Freight Management</Link>
                  </h3>
                  <p> No matter the nature of your cargo or its destination, we
                    offer a perfect blend of reliability, security, and speed...</p>
                  <Link to="/Freight" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img
                    src="assets/img/International Supply Chain Management.jpg"
                    alt="image"
                  />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Supply">
                      International Supply Chain Management
                    </Link>
                  </h3>
                  <p> By blending state-of-the-art technology, well-established
                    processes, and both local and international expertise, we..</p>
                  <Link to="/Supply" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img src="assets/img/Contract Logistics.jpg" alt="image" />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Contract">Contract Logistics</Link>
                  </h3>
                  <p> We enhance the entire supply chain by integrating all
                    processes, including storage, transportation, and</p>
                  <Link to="/Contract" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img
                    src="assets/img/Project Cargo Logistics.jpg"
                    alt="image"
                  />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Project">Project Cargo Logistics</Link>
                  </h3>
                  <p> Our specialized division, Singapore Project Cargo, manages
                    logistics for large and heavy lift project cargo required...</p>
                  <Link to="/Project" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img
                    src="assets/img/Global Trade Management.jpg"
                    alt="image"
                  />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Global">Global Trade Management</Link>
                  </h3>
                  <p> Global trade presents a challenge to businesses aiming to
                    thrive in the global economy. With the assistance of ..</p>
                  <Link to="/Global" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img src="assets/img/Custom Clearance.jpg" alt="image" />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Custom">Custom Clearance</Link>
                  </h3>
                  <p>While importers ultimately bear the responsibility for
                    submissions to customs authorities worldwide, our trusted</p>
                  <Link to="/Custom" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Services</span>
            <h2>CARGO MANAGED</h2>
            <p></p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/Perishables">
                    <img src="assets/img/Perishables.jpg" alt="image" />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/Perishables">Perishables</Link>
                  </h3>
                  <p>Handling perishable goods requires meticulous attention to
                    detail, in-depth product knowledge, and efficient..</p>
                  <Link to="/Perishables" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/General">
                    <img src="assets/img/General Cargo.jpg" alt="image" />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/General">General Cargo</Link>
                  </h3>
                  <p> {companyname} specializes in the management of
                    third/cross-country logistics. </p>
                  <Link to="/General" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/Dangerous">
                    <img src="assets/img/Dangerous Cargo.jpg" alt="image" />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/Dangerous">Dangerous Cargo</Link>
                  </h3>
                  <p>Our firm places an unwavering emphasis on safety. We
                    meticulously review all booking details, and when deemed..</p>
                  <Link to="/Dangerous" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 offset-md-3 offset-lg-0"></div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/Heavy">
                    <img src="assets/img/Heavy Lift.jpg" alt="image" />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/Heavy">Heavy Lift</Link>
                  </h3>
                  <p> {companyname} Company places the utmost importance on
                    delivering personalized and tailored services.</p>
                  <Link to="/Heavy" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-blog-post">
                <div className="post-image">
                  <Link to="/Trade">
                    <img
                      src="assets/img/Trade Shows & Exhibitions.jpg"
                      alt="image"
                    />
                  </Link>
                  <div className="date">...</div>
                </div>
                <div className="post-content">
                  <h3>
                    <Link to="/Trade">Trade Shows & Exhibitions</Link>
                  </h3>
                  <p> {companyname} Company places the utmost importance on delivering
                    personalized and tailored services.</p>
                  <Link to="/Trade" className="default-btn">
                    Read More <span />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
