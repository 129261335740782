import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <footer className="footer-area">
        <div className="container">
          <div className="subscribe-area">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="subscribe-content">
                  <h2>Join Our Newsletter</h2>
                  <p>
                   To get latest updates, Subscribe to our Newsletter
                  </p>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="subscribe-form">
                <form
                      className="newsletter-form"
                      action="/php/thankyou-subscribe.php "
                      method="POST"
                      onSubmit={(event) => handleSubmit(event)}
                    >
                      <div className="footer-widget__subscribe-input-box">
                        <input
                          onChange={(event) => handleSubemailChange(event)}
                          id="subemail"
                          value={subemail}
                          type="email"
                          name="subemail"
                          placeholder="Enter your email address"
                          required
                          className="input-newsletter"
                        />
                        <button
                          type="submit"
                         
                        >
                         Subscribe Now  <i className="flaticon-right-chevron" />
                        </button>
                      </div>
                    </form>
                    <h5 style={{ color: "red" }}>{result}</h5>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Quick Links</h3>
                <ul className="footer-quick-links">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li>
                    <Link to="/About">About Us</Link>
                  </li>
                  <li>
                    <Link to="/Whyus">Why Us</Link>
                  </li>
                
                  <li>
                    <Link to="/Services">Services</Link>
                  </li>
                  <li>
                    <Link to="/Industries">Industries</Link>
                  </li>
                  <li>
                    <Link to="/Values">Values</Link>
                  </li>
                  <li>
                    <Link to="/Faqs">FAQ's</Link>
                  </li>
                  <li>
                    <Link to="/Privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Terms">Terms</Link>
                  </li>
                  <li>
                    <Link to="/Careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/Getquote">Get Quote</Link>
                  </li>
                  <li>
                    <Link to="/Track">Track Shipment</Link>
                  </li>
                </ul>
              </div>
            </div>{" "}
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Services Links</h3>
                <ul className="footer-quick-links">
                  <li>
                    <Link to="/Freight">Freight Management </Link>
                  </li>
                  <li>
                    <Link to="/Perishables">Perishables </Link>
                  </li>
                  <li>
                    <Link to="/Supply">
                      International Supply Chain Management{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/General">General Cargo </Link>
                  </li>
                  <li>
                    <Link to="/Contract">Contract Logistics </Link>
                  </li>
                  <li>
                    <Link to="/Dangerous">Dangerous Cargo </Link>
                  </li>
                  <li>
                    <Link to="/Project">Project Cargo Logistics </Link>
                  </li>
                  <li>
                    <Link to="/Heavy">Heavy Lift </Link>
                  </li>
                  <li>
                    <Link to="/Global">Global Trade Management </Link>
                  </li>
                  <li>
                    <Link to="/Trade">Trade Shows & Exhibitions </Link>
                  </li>
                  <li>
                    <Link to="/Custom">Custom Clearance </Link>
                  </li>
                  
                 
                 
                  
                  
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
              <div className="single-footer-widget">
                <h3>Contact Info</h3>
                <ul className="footer-contact-info">
                  <li>
                    <span>Location:</span> {companyaddress}
                  </li>
                  <li>
                    <span>Email:</span>{" "}
                    <Link to="#">
                      <span
                        className="__cf_email__"
                        
                      >{companyemail}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <span>Website:</span> {companyurl}
                  </li>
                 
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6 col-md-6">
                <p>
                  <i className="far fa-copyright" /> {companyname} All Rights Reserved
                 
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 col-md-6">
                <ul>
                  <li>
                    <Link to="/Privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Terms">Terms &amp; Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
