import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Pharma & Healthcare</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Pharma & Healthcare</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Pharma & Healthcare</h3>
                  <p>
                    Hong Kong offers specialized transportation and integrated
                    logistics solutions tailored to the exacting standards of
                    the healthcare sector, particularly in the management of
                    pharmaceutical and medical product supply chains.
                  </p>

                  <p>
                    Our company features a dedicated healthcare division staffed
                    by a team of highly skilled professionals. They are
                    well-equipped to understand and fulfill the unique needs of
                    our customers, while providing various goods and
                    transportation solutions, including:
                  </p>

                  <ul>
                    <li>
                      Temperature-controlled products, with cooling solutions
                      and cooled room temperatures.
                    </li>
                    <li>
                      Surgical and laboratory supplies that do not require
                      temperature-controlled storage.
                    </li>
                    <li>Standard transport model products.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Pharma & Healthcare.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
