import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Oil And Gas</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Oil And Gas</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Oil And Gas</h3>
                  <p>
                    {companyname} focuses its solutions in the oil and gas
                    industry on International Supply Chain Management, Project
                    Management, Oil Rig Movements/Re-Supply, and Warehouse
                    Solutions.
                  </p>

                  <p>
                    In the oil and gas supply chains, safety holds paramount
                    importance. {companyname} offers specialized services to
                    assist you throughout the entire process, ensuring the
                    efficient and secure delivery of oil and gas products and
                    the management of global projects.
                  </p>

                  <p>
                    Our team, equipped with extensive technical knowledge, is
                    dedicated to supporting you in overseeing the complete
                    supply chain. We aim to meet the unique requirements of each
                    customer, providing specialized assistance across various
                    areas, from upstream supply for explorations to on-site
                    operational services, including OOS upgrades.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Oil And Gas.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
