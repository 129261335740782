import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>About Us</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      <section className="about-area ptb-100 bg-f8f8f8">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img
                  src="assets/img/About1.jpg"
                  style={{ height: "300px", width: "360px" }}
                  alt="image"
                />
                <img
                  src="assets/img/About2.jpg"
                  style={{ height: "300px", width: "360px" }}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <span>About Us</span>
                <h2>Wherever It Needs to Go, We Get It There.</h2>
                <p>
                  {companyname} stands as an environmentally conscious global
                  freight forwarding company. Our central focus lies not just in
                  meeting but, more importantly, surpassing expectations, which
                  forms the bedrock of our corporate strategy. We handle the
                  demanding logistics tasks, allowing our clients to relax and
                  unwind.
                </p>
                <p>
                  We firmly hold the belief that top-tier freight services
                  should be accessible to everyone. With our dedicated and
                  skilled team, we are wholeheartedly committed to delivering
                  unparalleled service in the industry. We work closely with our
                  valued clients to ensure their freight operations progress
                  seamlessly and efficiently, all while enhancing their
                  profitability.
                </p>
              </div>
            </div>
          </div>
          <div className="about-inner-area">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="about-text-box">
                  <h3>Our Mission</h3>
                  <p>
                    Our mission is to deliver superior maritime services,
                    streamline logistics processes, and operate modern port
                    terminals while ensuring the highest standards of safety and
                    security, ultimately becoming the go-to choice for our
                    valued clients.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3 col-sm-6">
                <div className="about-text-box">
                  <h3>Our Vision</h3>
                  <p>
                    Our vision is to establish ourselves as a market-leading
                    organization known for excellence in maritime services,
                    logistics solutions, and port operations on a global scale.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End About Area */}
      {/* Start Why Choose Us Area */}
      <section className="why-choose-us-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div
                className="why-choose-us-slides "
                style={{ background: 'url("../assets/img/banner.jpg")' }}
              >
                <div className="why-choose-us-image bg1">
                  <img src="assets/img/why-choose-img1.jpg" alt="image" />
                </div>
               
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="why-choose-us-content">
                <div className="content">
                  <div className="title">
                    <span className="sub-title">Why Us</span>
                    <h2>Why Choose Us</h2>
                    <p>
                      We strive to provide exceptional maritime services,
                      comprehensive logistics solutions, and state-of-the-art
                      port terminals. Our commitment to continuous improvement
                      and dedication to operating in a secure environment aim to
                      make us the preferred choice for our clients.
                    </p>
                  </div>
                  <ul className="features-list">
                    <li>
                      <span>GREAT SUPPORT</span>
                      Experience exceptional customer service with a dedicated
                      Personal Singapore Consultant, ensuring outstanding
                      support from our team.
                    </li>
                    <li>
                      <span>ONE STOP PROVIDER</span>
                      We serve as a comprehensive one-stop solution, offering a
                      wide array of services aimed at delivering top-tier
                      freight solutions.
                    </li>
                    <li>
                      <span>COMPETITIVE PRICING</span>
                      Benefit from competitive pricing as one of the largest
                      global freight forwarding companies, allowing us to pass
                      on cost savings to our valued customers.
                    </li>
                    <li>
                      <span>TRANSSHIPMENT</span>
                      Transshipment offers flexible and cost-effective shipping
                      routes, providing advantages to shippers (consignors).
                    </li>
                    <li>
                      <span>CONSOLIDATED FREIGHT</span>
                      Consolidation shipping services are ideal for businesses
                      or individuals with smaller cargo quantities, helping to
                      reduce costs by paying only for the space utilized.
                    </li>
                    <li>
                      <span>PICK AND PACK WAREHOUSING</span>
                      Pick and pack warehousing involves selecting products from
                      a warehouse based on customer orders and preparing them
                      for delivery as a crucial step in the shipping process.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-area ptb-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Freight Services</span>
            <h2>Explore our Freight Solutions</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img src="assets/img/Freight Management.jpg" alt="image" />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Freight">Freight Management</Link>
                  </h3>
                  <p> No matter the nature of your cargo or its destination, we
                    offer a perfect blend of reliability, security, and speed...</p>
                  <Link to="/Freight" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img
                    src="assets/img/International Supply Chain Management.jpg"
                    alt="image"
                  />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Supply">
                      International Supply Chain Management
                    </Link>
                  </h3>
                  <p> By blending state-of-the-art technology, well-established
                    processes, and both local and international expertise, we..</p>
                  <Link to="/Supply" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img src="assets/img/Contract Logistics.jpg" alt="image" />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Contract">Contract Logistics</Link>
                  </h3>
                  <p> We enhance the entire supply chain by integrating all
                    processes, including storage, transportation, and</p>
                  <Link to="/Contract" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img
                    src="assets/img/Project Cargo Logistics.jpg"
                    alt="image"
                  />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Project">Project Cargo Logistics</Link>
                  </h3>
                  <p> Our specialized division, Singapore Project Cargo, manages
                    logistics for large and heavy lift project cargo required...</p>
                  <Link to="/Project" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img
                    src="assets/img/Global Trade Management.jpg"
                    alt="image"
                  />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Global">Global Trade Management</Link>
                  </h3>
                  <p> Global trade presents a challenge to businesses aiming to
                    thrive in the global economy. With the assistance of ..</p>
                  <Link to="/Global" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-box">
                <div className="image">
                  <img src="assets/img/Custom Clearance.jpg" alt="image" />
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Custom">Custom Clearance</Link>
                  </h3>
                  <p>While importers ultimately bear the responsibility for
                    submissions to customs authorities worldwide, our trusted</p>
                  <Link to="/Custom" className="read-more-btn">
                    Learn More <i className="flaticon-right-chevron" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
