import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>International Supply Chain Management</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>International Supply Chain Management</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Blog Details Area */}
      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img
                    src="assets/img/International Supply Chain Management.jpg"
                    alt="image"
                  />
                </div>
                <div className="article-content">
                  <div className="entry-meta"></div>
                  <h3>International Supply Chain Management</h3>
                  <p>
                    By blending state-of-the-art technology, well-established
                    processes, and both local and international expertise, we
                    transform supply chains into a competitive edge. Our teams
                    craft resilient supply chains that respond to demand,
                    combining origin operations with destination logistics to
                    enhance productivity, efficiency, and profitability.
                  </p>
                  <p>
                    To sustain global expansion and facilitate worldwide
                    operations, dedicated international supply chain teams
                    collaborate across regions using a unified technological
                    platform with standardized procedures. Our Singapore
                    International Supply Chain (ISC) solution is founded on four
                    key pillars:
                  </p>
                  <ul>
                    <li>
                      <strong>Local Proficiency and Global Reach:</strong> We
                      combine local knowledge with international outreach.
                    </li>
                    <li>
                      <strong>Committed Account Teams:</strong> Our teams are
                      devoted to driving effectiveness.
                    </li>
                    <li>
                      <strong>Proven Methods and Techniques:</strong> We rely on
                      well-established approaches.
                    </li>
                    <li>
                      <strong>Cutting-Edge, Customizable Technology:</strong> We
                      utilize advanced, infinitely adaptable technology.
                    </li>
                  </ul>
                  <p>
                    <strong>ORIGIN SERVICES</strong>
                  </p>
                  <ul>
                    <li>PO (Purchase Order) Management</li>
                    <li>Vendor Management</li>
                    <li>Value Added Services</li>
                    <li>Consolidation/Load Optimization</li>
                    <li>Specialized Equipment</li>
                  </ul>
                  <p>
                    <strong>FREIGHT MANAGEMENT</strong>
                  </p>
                  <ul>
                    <li>Control Tower</li>
                    <li>Route Optimization</li>
                    <li>Carrier Management</li>
                    <li>Flow Management</li>
                  </ul>
                  <p>
                    <strong>DESTINATION MANAGEMENT</strong>
                  </p>
                  <ul>
                    <li>Customs and Cashflow Management</li>
                    <li>Port to DC (Distribution Center) Operations</li>
                    <li>WMS (Warehouse Management System)/Inventory</li>
                    <li>eSolutions</li>
                  </ul>
                  <p>
                    <strong>DELIVERING COMPETITIVE ADVANTAGE</strong>
                  </p>
                  <p>
                    We deliver process-driven and procedure-oriented global
                    supply chain solutions that consistently add value for our
                    clients. Our approach includes:
                  </p>
                  <ul>
                    <li>Supply Chain Solution Design</li>
                    <li>Engineering & Process Design</li>
                    <li>
                      SKU (Stock Keeping Unit) Part-Level Supply Chain
                      Visibility
                    </li>
                    <li>Flexible KPI (Key Performance Indicator) Reporting</li>
                    <li>
                      Regular Business Reviews Focused on Value Delivery &
                      Continuous Improvement
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_categories">
                  <h3 className="widget-title">Other Services</h3>
                  <ul>
                    <li>
                      <Link to="/Freight">Freight Management </Link>
                    </li>

                    <li>
                      <Link to="/Supply">
                        International Supply Chain Management{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/Contract">Contract Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Project">Project Cargo Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Global">Global Trade Management </Link>
                    </li>

                    <li>
                      <Link to="/Custom">Custom Clearance </Link>
                    </li>
                  </ul>
                </section>
                <section className="widget widget_recent_comments">
                  <h3 className="widget-title">Cargo Managed</h3>
                  <ul>
                    <li>
                      <Link to="/Perishables">Perishables </Link>
                    </li>
                    <li>
                      <Link to="/General">General Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Dangerous">Dangerous Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Heavy">Heavy Lift </Link>
                    </li>
                    <li>
                      <Link to="/Trade">Trade Shows & Exhibitions </Link>
                    </li>
                  </ul>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Details Area */}
      <Footer />
    </>
  );
}
