import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Reefer</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Reefer</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Reefer</h3>
                  <p>
                    Our proficiency in delivering comprehensive and efficient
                    solutions, coupled with unwavering commitments to safety and
                    quality, is underpinned by our experienced team in the
                    mining industry. We cultivate trust with our clients by
                    offering straightforward solutions to intricate logistical
                    challenges.
                  </p>

                  <p>
                    Our expertise in mining operations and projects has afforded
                    us a profound understanding of our clients' requirements. It
                    has also instilled a sense of urgency and precision within
                    our staff, vital for meeting the exacting standards of the
                    sector, including:
                  </p>

                  <ul>
                    <li>
                      Maintaining rigorous standards for quality and safety.
                    </li>
                    <li>
                      Pioneering innovations to enhance supply chain efficiency.
                    </li>
                    <li>Adhering to environmental, social, and legal norms.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Reefer.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
