import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Construction</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Construction</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Construction</h3>
                  <p>
                    {companyname} offers specialized solutions tailored to the
                    diverse needs of the construction industry sectors. Our top
                    priority is to provide the necessary resources for the safe
                    storage, handling, and distribution of construction supplies
                    while maintaining the highest standards of quality and
                    customer service.
                  </p>

                  <p>
                    Effective coordination is essential for managing
                    construction projects successfully, and timely supply of
                    required building materials plays a crucial role. Logistics
                    plays a key role in ensuring the smooth flow of materials.
                  </p>

                  <p>
                    With a specialized supply strategy and flexible warehouse
                    options designed for each construction project, we can
                    ensure the availability of materials at all times.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Construction.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
