import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Project Cargo Logistics</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Project Cargo Logistics</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Blog Details Area */}
      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img
                    src="assets/img/Project Cargo Logistics.jpg"
                    alt="image"
                  />
                </div>
                <div className="article-content">
                  <div className="entry-meta"></div>
                  <h3>Project Cargo Logistics</h3>
                  <p>
                    Our specialized division, Singapore Project Cargo, manages
                    logistics for large and heavy lift project cargo required
                    for industrial projects.
                  </p>
                  <p>
                    We have the capability to transport oversized and heavy
                    cargo to destinations worldwide, regardless of location,
                    complexity, or size. This necessitates careful planning from
                    the outset to minimize additional costs and address
                    regulatory requirements such as taxes and customs
                    compliance.
                  </p>
                  <p>
                    We possess extensive experience in specialized project cargo
                    logistics for industries including Oil & Gas, Renewable
                    Energy, Mining, Port Equipment, Rail, and Manufacturing.
                  </p>
                  <p>
                    Our knowledgeable staff assesses the project's scale and
                    coordinates all necessary resources to ensure maximum
                    delivery efficiency.
                  </p>
                  <p>
                    <strong>CUSTOMIZED SERVICES FOR OVERSIZED CARGO</strong>
                  </p>
                  <p>
                    We offer a wide range of services, leveraging our extensive
                    background knowledge in logistics management and customized
                    travel arrangements from point A to point B.
                  </p>
                  <p>
                    <strong>ROUTE STUDIES</strong>
                  </p>
                  <p>
                    We conduct comprehensive route studies to identify potential
                    transportation obstacles and devise solutions to assess the
                    feasibility of transportation.
                  </p>
                  <p>
                    <strong>DOOR TO DOOR TRANSPORT</strong>
                  </p>
                  <p>
                    Our services encompass door-to-door cargo transportation,
                    including all relevant procedures and disciplines.
                  </p>
                  <p>
                    <strong>CUSTOMS CONSULTANCY</strong>
                  </p>
                  <p>
                    Customs procedures are integral to project development. We
                    provide support and in-depth research on the requirements of
                    each nation to ensure that they do not hinder the project's
                    progress.
                  </p>
                  <p>
                    <strong>VESSEL CHARTERING</strong>
                  </p>
                  <p>
                    We specialize in international chartering and have extensive
                    experience collaborating with various vessel owners across
                    all continents.
                  </p>
                  <p>
                    <strong>RIVER TRANSPORTATION</strong>
                  </p>
                  <p>
                    We excel in transferring heavy cargoes via river waterways
                    as an alternative to land transport.
                  </p>
                  <p>
                    <strong>POSITIONING OVER PEDESTALS</strong>
                  </p>
                  <p>
                    If necessary, we provide instructions on positioning
                    equipment such as HRSG, SUPER MODULES, TRAFOS, GAS AND STEAM
                    gensets, etc., on pedestals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_categories">
                  <h3 className="widget-title">Other Services</h3>
                  <ul>
                    <li>
                      <Link to="/Freight">Freight Management </Link>
                    </li>

                    <li>
                      <Link to="/Supply">
                        International Supply Chain Management{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/Contract">Contract Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Project">Project Cargo Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Global">Global Trade Management </Link>
                    </li>

                    <li>
                      <Link to="/Custom">Custom Clearance </Link>
                    </li>
                  </ul>
                </section>
                <section className="widget widget_recent_comments">
                  <h3 className="widget-title">Cargo Managed</h3>
                  <ul>
                    <li>
                      <Link to="/Perishables">Perishables </Link>
                    </li>
                    <li>
                      <Link to="/General">General Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Dangerous">Dangerous Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Heavy">Heavy Lift </Link>
                    </li>
                    <li>
                      <Link to="/Trade">Trade Shows & Exhibitions </Link>
                    </li>
                  </ul>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Details Area */}
      <Footer />
    </>
  );
}
