import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Global Trade Management</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Global Trade Management</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Blog Details Area */}
      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img
                    src="assets/img/Global Trade Management.jpg"
                    alt="image"
                  />
                </div>
                <div className="article-content">
                  <div className="entry-meta"></div>
                  <h3>Global Trade Management</h3>
                  <p>
                    Global trade presents a challenge to businesses aiming to
                    thrive in the global economy. With the assistance of Singapore, you can streamline the entire lifecycle of global
                    trade, enhancing operational efficiencies and cash flows.
                  </p>
                  <p>
                    Wherever your trade activities take place, Singapore can
                    help you establish and maintain trade compliance systems,
                    identify opportunities for global business expansion, and
                    provide support across various aspects of your trade
                    operations.
                  </p>
                  <p>
                    <strong>
                      TRADE CONSULTING SERVICES TO BOOST BUSINESS EFFICIENCY
                    </strong>
                  </p>
                  <p>
                    Our commitment to delivering expert trade consultancy
                    services is evident. We focus on six key areas to maximize
                    regulatory compliance, mitigate risks, improve supply chain
                    efficiency, and expand our clients' businesses:
                  </p>
                  <p>
                    <strong>GLOBAL CUSTOMS BROKERAGE</strong>
                  </p>
                  <p>
                    Our passionate and skilled professionals assist in
                    seamlessly navigating the global borders to ensure the
                    smooth movement of your goods.
                  </p>
                  <p>
                    <strong>IMPORT CONSULTING</strong>
                  </p>
                  <p>
                    We help you adhere to industry best practices for product
                    classification, valuation, country of origin determination,
                    country of origin markings, and record-keeping. Our services
                    also enhance your understanding of import duties,
                    documentation, processes, and product requirements to reduce
                    tariffs, enhance compliance, and streamline the importation
                    of goods.
                  </p>
                  <p>
                    <strong>EXPORT CONSULTING</strong>
                  </p>
                  <p>
                    Our focus is on best practices for export operations,
                    including documentation, inspections, certifications,
                    licenses, and product-specific requirements. We ensure a
                    positive experience with your international customers.
                  </p>
                  <p>
                    <strong>SUPPLY CHAIN SECURITY CONSULTING</strong>
                  </p>
                  <p>
                    We concentrate on reducing security risks within your supply
                    chain to facilitate the secure movement of cargo. This
                    results in a more efficient supply chain with quicker
                    customs clearance services, fewer inspections, and
                    participation in globally trusted trader programs.
                  </p>
                  <p>
                    <strong>DUTY MITIGATION CONSULTING</strong>
                  </p>
                  <p>
                    Our duty mitigation consulting services start at the
                    beginning, focusing on product classification, tariffs, and
                    duty deferral programs (e.g., Free Trade Zones, Bonded
                    Warehouses, and Duty Drawback). These programs help our
                    clients minimize duty obligations and maximize profit
                    margins for business expansion.
                  </p>
                  <p>
                    <strong>
                      TRADE AGREEMENT AND TRADE PROGRAM CONSULTING (TATPC)
                    </strong>
                  </p>
                  <p>
                    We delve into the bill of materials for your products,
                    sourcing operations, and country-of-origin requirements to
                    help you make informed decisions about sourcing locations.
                    This ensures that you fully leverage trade agreements and
                    expand your business effectively.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_categories">
                  <h3 className="widget-title">Other Services</h3>
                  <ul>
                    <li>
                      <Link to="/Freight">Freight Management </Link>
                    </li>

                    <li>
                      <Link to="/Supply">
                        International Supply Chain Management{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/Contract">Contract Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Project">Project Cargo Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Global">Global Trade Management </Link>
                    </li>

                    <li>
                      <Link to="/Custom">Custom Clearance </Link>
                    </li>
                  </ul>
                </section>
                <section className="widget widget_recent_comments">
                  <h3 className="widget-title">Cargo Managed</h3>
                  <ul>
                    <li>
                      <Link to="/Perishables">Perishables </Link>
                    </li>
                    <li>
                      <Link to="/General">General Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Dangerous">Dangerous Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Heavy">Heavy Lift </Link>
                    </li>
                    <li>
                      <Link to="/Trade">Trade Shows & Exhibitions </Link>
                    </li>
                  </ul>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Details Area */}
      <Footer />
    </>
  );
}
