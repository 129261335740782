import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Automotive</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Automotive</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Automotive</h3>
                  <p>
                    At Hong Kong, we specialize in providing tailored shipping
                    and comprehensive logistics solutions to the automotive
                    industry, always upholding the highest standards of quality
                    and customer satisfaction.
                  </p>

                  <p>
                    Our services are designed to meet the diverse needs of
                    various automotive businesses, including car suppliers,
                    manufacturers, importers, and distributors, covering every
                    aspect of the automotive supply chain.
                  </p>

                  <p>
                    With our warehouse services and a wide array of
                    transportation options, you can trust that your automotive
                    parts and vehicles will be delivered to their destination
                    promptly.
                  </p>

                  <p>
                    We are dedicated to offering guidance and suggesting
                    cost-effective strategies that maintain the highest quality
                    standards, ensuring that you receive exceptional service.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Automotive.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
