import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Custom Clearance</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Custom Clearance</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Blog Details Area */}
      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img src="assets/img/Custom Clearance.jpg" alt="image" />
                </div>
                <div className="article-content">
                  <div className="entry-meta"></div>
                  <h3>Custom Clearance</h3>
                  <p>
                    While importers ultimately bear the responsibility for
                    submissions to customs authorities worldwide, our trusted
                    team of international customs brokerage professionals is
                    here to assist you with your routine import and export
                    operations.
                  </p>
                  <p>
                    Our experts go the extra mile to gain a comprehensive
                    understanding of your personnel, products, processes, and
                    specific needs. Leveraging their expertise and knowledge,
                    they prepare swift and accurate import clearances and
                    security declarations in compliance with the regulations of
                    all participating countries.
                  </p>
                  <p>
                    To ensure the timely arrival of your import shipments at
                    their destinations, our Global Customs Brokerage teams act
                    as intermediaries, negotiating on your behalf with customs
                    authorities, carriers, and warehouses.
                  </p>
                  <p>
                    <strong>KEY SERVICES INCLUDE:</strong>
                  </p>
                  <p>
                    <strong>
                      Customs Entry Formalities / Customs Clearance:
                    </strong>{" "}
                    Streamlining the process to ensure efficient customs
                    clearance.
                  </p>
                  <p>
                    <strong>Security Filing:</strong> Handling security filings
                    to meet regulatory requirements.
                  </p>
                  <p>
                    <strong>Additional Regulatory Agency Clearances:</strong>{" "}
                    Assisting with clearances from additional regulatory
                    agencies as needed.
                  </p>
                  <p>
                    <strong>
                      Visibility / Customs Milestone Reporting / Electronic
                      Documentation:
                    </strong>{" "}
                    Providing visibility into customs milestones and electronic
                    documentation for tracking.
                  </p>
                  <p>
                    <strong>Access to Licensed Brokers:</strong> Ensuring access
                    to licensed brokers for specialized needs.
                  </p>
                  <p>
                    <strong>Pre-Shipment Trade Compliance:</strong> Offering
                    solutions related to international trade compliance before
                    shipment.
                  </p>
                  <p>
                    <strong>Inspections and Surveillance:</strong> Coordinating
                    inspections and surveillance as required.
                  </p>
                  <p>
                    <strong>Advisory Services:</strong> Providing advisory
                    services to address international trade-related issues.
                  </p>
                  <p>
                    <strong>Classification and Valuation Research:</strong>{" "}
                    Conducting research on classification and valuation matters.
                  </p>
                  <p>
                    <strong>Special Customs Regulations:</strong> Assisting with
                    compliance regarding special customs regulations.
                  </p>
                  <p>
                    <strong>Bonded Warehouses:</strong> Managing operations
                    related to bonded warehouses.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_categories">
                  <h3 className="widget-title">Other Services</h3>
                  <ul>
                    <li>
                      <Link to="/Freight">Freight Management </Link>
                    </li>

                    <li>
                      <Link to="/Supply">
                        International Supply Chain Management{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/Contract">Contract Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Project">Project Cargo Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Global">Global Trade Management </Link>
                    </li>

                    <li>
                      <Link to="/Custom">Custom Clearance </Link>
                    </li>
                  </ul>
                </section>
                <section className="widget widget_recent_comments">
                  <h3 className="widget-title">Cargo Managed</h3>
                  <ul>
                    <li>
                      <Link to="/Perishables">Perishables </Link>
                    </li>
                    <li>
                      <Link to="/General">General Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Dangerous">Dangerous Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Heavy">Heavy Lift </Link>
                    </li>
                    <li>
                      <Link to="/Trade">Trade Shows & Exhibitions </Link>
                    </li>
                  </ul>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Details Area */}
      <Footer />
    </>
  );
}
