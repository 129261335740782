import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      
      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>container dimensions</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>container dimensions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="about-section">
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className="container">
        <div className=" g-5 align-items-center mb-5">
        <div className=" m-5 wow fadeIn text-center" data-wow-delay="0.3s" style={{width: '1000px'}}>
          <h1 className="display-6 mb-3 p-4">Container Specifications</h1>
        </div>
        <center><h2 id="standard-shipping-container-dimensions">Standard shipping container dimensions</h2></center>
        <h4 id="20-ft-standard-shipping-container-dimensions">20 ft standard shipping container dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>22GP</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2} style={{maxWidth: '355px'}}>
                <h4><b>The 20 ft standard shipping container is for general purpose cargo.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>7' 8 ⅛"</td>
              <td>7' 6 ¼"</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>2.34 m</td>
              <td>2.29 m</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7' 8 ⅝"</td>
              <td>7' 6 ¼"</td>
              <td>19' 4 ¼"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.34 m</td>
              <td>2.29 m</td>
              <td>5.9 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>52,831 lbs</td>
              <td>4,914 lbs</td>
              <td>47,899 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>23,956 kg</td>
              <td>2,229 kg</td>
              <td>21,727 kg</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In CFT</td>
              <td>In CBM</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>1,172 CFT</td>
              <td>33.2 CBM</td>
              <td />
            </tr>
          </tbody>
        </table>
        <h4 id="40-ft-standard-shipping-container-dimensions">40 ft standard shipping container dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>42GP</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2} style={{maxWidth: '355px'}}>
                <h4><b>The 40 ft standard shipping container is for general purpose cargo.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>7' 8 ⅛"</td>
              <td>7' 6 ¼"</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>2.34 m</td>
              <td>2.29 m</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7' 8 ⅝"</td>
              <td>7' 10 ¼"</td>
              <td>37' 11 ¼"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.352 m</td>
              <td>2.395 m</td>
              <td>12.01 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>67,199 lbs</td>
              <td>5,220 lbs</td>
              <td>59,039 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>30,481 kg</td>
              <td>3,701 kg</td>
              <td>26,780 kg</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In CFT</td>
              <td>In CBM</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>2,389.75 CFT</td>
              <td>67.67 CBM</td>
              <td />
            </tr>
          </tbody>
        </table>
        <h4 id="40-ft-high-cube-standard-shipping-container-dimensions">40 ft high cube standard shipping container
          dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>40HQ or 40HC</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2}>
                <h4><b>The 40 ft high cube shipping container is larger than the 40 ft standard shipping
                    container.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>7' 8 ⅛"</td>
              <td>8' 6 ¼"</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>2.34 m</td>
              <td>2.56 m</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7' 8 ⅝"</td>
              <td>8' 10 ⅛"</td>
              <td>39' 5 ⅝"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.352 m</td>
              <td>2.69 m</td>
              <td>12.01 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>67,196 lbs</td>
              <td>8,747 lbs</td>
              <td>58,448 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>30,480 kg</td>
              <td>3,968 kg</td>
              <td>26,512 kg</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In CFT</td>
              <td>In CBM</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>2,694 CFT</td>
              <td>76.3 CBM</td>
              <td />
            </tr>
          </tbody>
        </table>
        <h4 id="45-ft-high-cube-standard-shipping-container-dimensions">45 ft high cube standard shipping container
          dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>L5GO</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2}>
                <h4><b>The 45 ft high cube shipping container is for general purpose cargo.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>7' 9½"</td>
              <td>8' 5 ¾"</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>2.37 m</td>
              <td>2.58 m</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7' 11"</td>
              <td>8' 10"</td>
              <td>44' 4 ¾"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.41 m</td>
              <td>2.69 m</td>
              <td>13.53 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>74,960 lbs</td>
              <td>10,910 lbs</td>
              <td>64,050 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>3,4000 kg</td>
              <td>4,950 kg</td>
              <td>29,050 kg</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In CFT</td>
              <td>In CBM</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>3,122 CFT</td>
              <td>88.4 CBM</td>
              <td />
            </tr>
          </tbody>
        </table>
        <center><h2 id="ventilated-shipping-container-dimensions">Ventilated shipping container dimensions</h2></center>
        <h4 id="20-ft-ventilated-shipping-container-dimensions">20 ft ventilated shipping container dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>22VH</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2} style={{maxWidth: '355px'}}>
                <h4><b>The 20 ft ventilated shipping container is for cargo that requires ventilation,
                    typically used to transport green coffee beans or similar cargo.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>7' 8 ⅛"</td>
              <td>7' 6 ¼"</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>2.34 m</td>
              <td>2.29 m</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7' 8 ⅝"</td>
              <td>7' 10 ¼"</td>
              <td>19' 4 ¼"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.352 m</td>
              <td>2.395 m</td>
              <td>5.9 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>52,897 lbs</td>
              <td>5,297 lbs</td>
              <td>47,899 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>32,500 kg</td>
              <td>2,394 kg</td>
              <td>21,727 kg</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In CFT</td>
              <td>In CBM</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>1,172 CFT</td>
              <td>33.2 CBM</td>
              <td />
            </tr>
          </tbody>
        </table>
        <center><h2 id="refrigerated-shipping-container-dimensions">Refrigerated shipping container dimensions</h2></center>
        <h4 id="20-ft-refrigerated-shipping-container-dimensions">20 ft refrigerated shipping container dimensions
        </h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>22RT</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2} style={{maxWidth: '355px'}}>
                <h4><b>The 20 ft refrigerated shipping container is for cargo that requires temperature
                    control.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>7' 8 ⅛"</td>
              <td>7' 6 ¼"</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>2.34 m</td>
              <td>2.29 m</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7'6"</td>
              <td>7' 4 ⅞"</td>
              <td>17' 11 ⅝"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.28 m</td>
              <td>2.33 m</td>
              <td>5.45 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>67,200 lbs</td>
              <td>6,970 lbs</td>
              <td>60,230 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>30,480 kg</td>
              <td>3,160 kg</td>
              <td>27,320 kg</td>
            </tr>
          </tbody>
        </table>
        <h4 id="40-ft-refrigerated-shipping-container-dimensions">40 ft refrigerated shipping container dimensions
        </h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>42RT</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2}>
                <h4><b>The 40 ft refrigerated shipping container is for cargo that requires temperature
                    control or perishable cargo.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>7' 5 ⅜"</td>
              <td>6' 11 ⅞"</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>2.26 m</td>
              <td>2.13 m</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7'5 ⅜"</td>
              <td>7' 1 ⅞"</td>
              <td>37' 11 ¼"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.26 m</td>
              <td>2.18 m</td>
              <td>11.48 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>67,053 lbs</td>
              <td>10,778 lbs</td>
              <td>56,275 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>30,415 kg</td>
              <td>4,889 kg</td>
              <td>25,526 kg</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In CFT</td>
              <td>In CBM</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>2,039.7 CFT</td>
              <td>57.76 CBM</td>
              <td />
            </tr>
          </tbody>
        </table>
        <center><h2 id="flat-rack-shipping-container-dimensions">Flat rack shipping container dimensions</h2></center>
        <h4 id="20-ft-flat-rack-shipping-container-dimensions">20 ft flat rack shipping container dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>22PC</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2} style={{maxWidth: '355px'}}>
                <h4><b>The 20 ft flat rack shipping container is for unusually heavy loads, over-sized
                    cargo, construction equipment, building supplies or heavy machinery.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>8'</td>
              <td>7' 3 ⅞"</td>
              <td>19' 9 ¾"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.438 m</td>
              <td>2.233 m</td>
              <td>5.638 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>99,200 lbs</td>
              <td>6,400 lbs</td>
              <td>92,800 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>45,000 kg</td>
              <td>2,900 kg</td>
              <td>42,100 kg</td>
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>1,154.3 CFT</td>
              <td>32.7 CBM</td>
            </tr>
          </tbody>
        </table>
        <h4 id="40-ft-flat-rack-shipping-container-dimensions">40 ft flat rack shipping container dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>42PC</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2}>
                <h4><b>The 40 ft flat rack shipping container is for over-sized cargo, heavy machinery,
                    construction equipment, or supplies.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>NA</td>
              <td>NA</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>NA</td>
              <td>NA</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7' 4"</td>
              <td>7' 5 ⅓"</td>
              <td>39' 3 ⅞"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.23 m</td>
              <td>2.27 m</td>
              <td>11.98 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>98,326 lbs</td>
              <td>12,897 lbs</td>
              <td>85,429 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>44,600 kg</td>
              <td>5,850 kg</td>
              <td>38,750 kg</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In CFT</td>
              <td>In CBM</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>1,860 CFT</td>
              <td>52.7 CBM</td>
              <td />
            </tr>
          </tbody>
        </table>
        <center><h2 id="open-top-shipping-container-dimensions">Open top shipping container dimensions</h2></center>
        <h4 id="20-ft-open-top-shipping-container-dimensions">20 ft open top shipping container dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>22U1</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2} style={{maxWidth: '355px'}}>
                <h4><b>The 20 ft open top shipping container is for over-height cargo and top loading.</b>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>7' 8 ⅛"</td>
              <td>7' 6 ¼"</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>2.34 m</td>
              <td>2.29 m</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7' 8 ⅝"</td>
              <td>7' 10 ¼"</td>
              <td>19' 4 ¼"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.352 m</td>
              <td>2.395 m</td>
              <td>5.9 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>52,897 lbs</td>
              <td>5,297 lbs</td>
              <td>47,619 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>23,994 kg</td>
              <td>2,394 kg</td>
              <td>21,600 kg</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In CFT</td>
              <td>In CBM</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>1,172 CFT</td>
              <td>33.2 CBM</td>
              <td />
            </tr>
          </tbody>
        </table>
        <h4 id="40-ft-open-top-shipping-container-dimensions">40 ft open top shipping container dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>42OT</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2}>
                <h4><b>The 40 ft open top shipping container is for top loading for excessively long or high
                    cargo.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Door opening in feet</b></td>
              <td>7' 8 ⅛"</td>
              <td>7' 6 ¼"</td>
              <td />
            </tr>
            <tr>
              <td><b>Door opening in meters</b></td>
              <td>2.34 m</td>
              <td>2.29 m</td>
              <td />
            </tr>
            <tr>
              <td><b>Interior dimensions in feet</b></td>
              <td>7' 8 ⅝"</td>
              <td>7' 10 ¼"</td>
              <td>39' 5 ⅝"</td>
            </tr>
            <tr>
              <td><b>Interior dimensions in meters</b></td>
              <td>2.352 m</td>
              <td>2.395 m</td>
              <td>12.01 m</td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Weight</b></h4>
              </td>
              <td>Max Gross</td>
              <td>Tare</td>
              <td>Max Payload</td>
            </tr>
            <tr>
              <td><b>Weight in lbs</b></td>
              <td>67,196 lbs</td>
              <td>8,487 lbs</td>
              <td>58,709 lbs</td>
            </tr>
            <tr>
              <td><b>Weight in kg</b></td>
              <td>30,480 kg</td>
              <td>3,850 kg</td>
              <td>26,630 kg</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In CFT</td>
              <td>In CBM</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>2,389.75 CFT</td>
              <td>66.67 CBM</td>
              <td />
            </tr>
          </tbody>
        </table>
        <center><h2 id="iso-tank-shipping-container-dimensions">ISO tank shipping container dimensions</h2></center>
        <h4 id="20-ft-iso-tank-shipping-container-dimensions">20 ft ISO tank shipping container dimensions</h4>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td>
                <h4><b>Container Reference Code:</b></h4>
              </td>
              <td>
                <h4><b>22TO</b></h4>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h4><b>Container Application:</b></h4>
              </td>
              <td colSpan={2}>
                <h4><b>The 20 ft tank shipping container is for food stuffs, oils, and chemicals.</b></h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4><b>Container Dimensions:</b></h4>
              </td>
              <td>Width</td>
              <td>Height</td>
              <td>Length</td>
            </tr>
            <tr>
              <td><b>Exterior dimensions in feet</b></td>
              <td>8'</td>
              <td>8' 6"</td>
              <td>20"</td>
            </tr>
            <tr>
              <td><b>Exterior dimensions in meters</b></td>
              <td>2.43 m</td>
              <td>2.59 m</td>
              <td>6.09 m</td>
            </tr>
            <tr>
              <td><b>Container Volume</b></td>
              <td>In U.S. Gallons</td>
              <td>In Liters</td>
              <td />
            </tr>
            <tr>
              <td><b>Load Capacity</b></td>
              <td>6,450 gl</td>
              <td>26,001 lt</td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_70" />
        {/*===============spacing==============*/}
      </section>

      <Footer />
    </>
  );
}
