import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page_header_default style_one ">
        <div className="parallax_cover">
          <div className="simpleParallax">
            <img
              src="assets/images/page-header-default.jpg"
              alt="bg_image"
              className="cover-parallax"
            />
          </div>
        </div>
        <div className="page_header_content">
          <div className="auto-container">
            <div className="row">
              <div className="col-md-12" style={{ marginTop: "200px" }}>
                <div className="banner_title_inner">
                  <div className="title_page">Intermodal</div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="breadcrumbs creote">
                  <ul className="breadcrumb m-auto">
                    <li>
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="active">Intermodal</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="about-section">
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 ">
              <div className="about_content position-relative z_99">
                <div className="title_all_box style_one text-left  dark_color">
                  <div className="title_sections">
                    <div className="before_title">Industries</div>
                    <h2>Intermodal</h2>
                  </div>
                </div>

                {/*===============spacing==============*/}
                <div className="description_box">
                  <p>
                    We're masters of amalgamation when it comes to transport
                    operations. We expertly blend the advantages of rail and
                    road, water and air, seamlessly weaving them into a single
                    transport chain.
                  </p>

                  <p>
                    Our unaccompanied combined transport services offer a
                    comprehensive spectrum of solutions catering to goods of all
                    types. We navigate the journey of your goods utilizing
                    containers, swap bodies, and craneable semi-trailers,
                    spanning across Singapore and even extending beyond to Asia.
                    Our multimodal transport system is designed to harmoniously
                    integrate the most optimal modes of transportation, forming
                    a cohesive and efficient transport chain.
                  </p>

                  <p>
                    By harnessing the reliability and capacity of railways, the
                    flexibility of road transportation, the intercontinental
                    prowess of ships, and the swift speed of aircraft, we create
                    a bespoke intermodal transport performance that addresses a
                    spectrum of needs. The backbone of our system is grounded in
                    rail transport, supported by our high-frequency block train
                    network connecting two strategically positioned terminals.
                    The result? An optimized, efficient, and flexible intermodal
                    transport solution that caters to diverse transportation
                    needs.
                  </p>
                </div>
                {/*===============spacing==============*/}
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="image_boxes style_two">
                <img
                  src="assets/images/shape-1.png"
                  className="background_image"
                  alt="image"
                />
                <div className="image ">
                  <img
                    src="assets/images/Intermodal.jpg"
                    className="img-fluid"
                    alt="image"
                  />
                </div>

                <div className="authour_quotes">
                  <i className="icon-quote" />
                  <h6>Intermodal</h6>
                  <p>Industries</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_70" />
        {/*===============spacing==============*/}
      </section>

      <Footer />
    </>
  );
}
