import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Freight from "./pages/Freight";
import Supply from "./pages/Supply";
import Contract from "./pages/Contract";
import Global from "./pages/Global";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Project from "./pages/Project";
import Mining from "./pages/Mining";
import Reefer from "./pages/Reefer";
import Chemical from "./pages/Chemical";
import Retail from "./pages/Retail";

import Oil from "./pages/Oil";
import Track from "./pages/Track";
import Trace from "./pages/Trace";
import Renewable from "./pages/Renewable";
import Construction from "./pages/Construction";
import Automotive from "./pages/Automotive";
import Furniture from "./pages/Furniture";
import Custom from "./pages/Custom";
import Intermodal from "./pages/Intermodal";
import Warehousing from "./pages/Warehousing";
import Perishables from "./pages/Perishables";
import General from "./pages/General";
import Food from "./pages/Food";
import Dangerous from "./pages/Dangerous";
import Container from "./pages/Container";
import Whyus from "./pages/Whyus";
import Careers from "./pages/Careers";
import Incoterms from "./pages/Incoterms";
import Dlabels from "./pages/Dlabels";
import Missionvision from "./pages/Missionvision";
import Faqs from "./pages/Faqs";
import Industries from "./pages/Industries";
import Requestquote from "./pages/Requestquote";
import Glossary from "./pages/Glossary";
import Pharma from "./pages/Pharma";
import Heavy from "./pages/Heavy";
import Trade from "./pages/Trade";
import Fashion from "./pages/Fashion";

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Heavy" element={<Heavy />} />
          <Route path="/Fashion" element={<Fashion />} />
          <Route path="/Trade" element={<Trade />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Glossary" element={<Glossary />} />
          <Route path="/Pharma" element={<Pharma />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Freight" element={<Freight />} />
          <Route path="/Supply" element={<Supply />} />
          <Route path="/Contract" element={<Contract />} />
          <Route path="/Requestquote" element={<Requestquote />} />
          <Route path="/Global" element={<Global />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Renewable" element={<Renewable />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Mining" element={<Mining />} />
          <Route path="/Reefer" element={<Reefer />} />
          <Route path="/Chemical" element={<Chemical />} />
          <Route path="/Retail" element={<Retail />} />
          <Route path="/Oil" element={<Oil />} />
          <Route path="/Track" element={<Track />} />
          <Route path="/Trace" element={<Trace />} />
          <Route path="/Construction" element={<Construction />} />
          <Route path="/Automotive" element={<Automotive />} />
          <Route path="/Furniture" element={<Furniture />} />
          <Route path="/Custom" element={<Custom />} />
          <Route path="/Intermodal" element={<Intermodal />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Perishables" element={<Perishables />} />
          <Route path="/General" element={<General />} />
          <Route path="/Food" element={<Food />} />
          <Route path="/Dangerous" element={<Dangerous />} />
          <Route path="/Container" element={<Container />} />
          <Route path="/Whyus" element={<Whyus />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Missionvision" element={<Missionvision />} />
          <Route path="/Incoterms" element={<Incoterms />} />
          <Route path="/Dlabels" element={<Dlabels />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/Industries" element={<Industries />} />
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
