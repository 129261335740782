import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Furniture</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Furniture</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Furniture</h3>
                  <p>
                    {companyname}'s global network of offices ensures that your
                    furniture products are delivered to their destination on
                    time and in pristine condition.
                  </p>

                  <p>
                    While no two furniture manufacturers, importers, or
                    retailers are identical, they all encounter similar
                    logistical challenges. Transportation issues are a common
                    concern in the industry, and production-related delays often
                    disrupt even the most efficient delivery schedules.
                    Regardless of the location where furniture is manufactured,
                    supplied, or sold, {companyname} Freight offers a range of
                    services to address these challenges. Our services include
                    end-to-end supply chain visibility, just-in-time (JIT)
                    inventory management, global cost analysis, and support for
                    e-commerce requirements.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Furniture.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
