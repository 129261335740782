import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />

      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Frequently Asked Questions</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Frequently Asked Questions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="faq-area ptb-100">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="faq-image">
                <img src="assets/img/faq.png" alt="image" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="faq-accordion">
                <span className="sub-title">Frequently Asked Questions</span>
                <h2>Get Every Single Answers There if you want</h2>
                <ul className="accordion">
                  <li className="accordion-item">
                    <a className="accordion-title active">
                      <i className="fas fa-plus" />
                      What is a freight forwarding company?
                    </a>
                    <p className="accordion-content show">
                      A freight forwarding company is a third-party logistics
                      provider that specializes in coordinating the
                      transportation and shipment of goods on behalf of shippers
                      or businesses. They manage various aspects of the supply
                      chain, including shipping, customs clearance,
                      documentation, and logistics
                    </p>
                  </li>
                  <li className="accordion-item">
                    <a className="accordion-title active">
                      <i className="fas fa-plus" />
                      How do freight forwarders handle shipping documentation?
                    </a>
                    <p className="accordion-content show">
                      Freight forwarders are responsible for preparing and
                      managing shipping documentation, including bills of
                      lading, invoices, packing lists, certificates of origin,
                      and customs declarations. Accurate documentation is
                      crucial for efficient shipment and customs clearance.
                    </p>
                  </li>
                  <li className="accordion-item">
                    <a className="accordion-title active">
                      <i className="fas fa-plus" />
                      What services do freight forwarding companies offer?
                    </a>
                    <p className="accordion-content show">
                      Many freight forwarders offer cargo insurance to protect
                      shipments against loss or damage during transit. However,
                      insurance terms and coverage may vary, so it's important
                      to discuss insurance options with your chosen forwarder.
                    </p>
                  </li>
                  <li className="accordion-item">
                    <a className="accordion-title active">
                      <i className="fas fa-plus" />
                      Do freight forwarders provide cargo insurance?
                    </a>
                    <p className="accordion-content show">
                      Many freight forwarders offer cargo insurance to protect
                      shipments against loss or damage during transit. However,
                      insurance terms and coverage may vary, so it's important
                      to discuss insurance options with your chosen forwarder.
                    </p>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </>
  );
}
