import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Industry Solutions</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Industry Solutions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="team-area ptb-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Our Industries</span>
            <h2>Explore Our Industry Sectors</h2>
           
          </div>
          <div className="team-slides row ">
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Automotive.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Automotive" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Automotive</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Fashion.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Fashion" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Fashion</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Mining.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Mining" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Mining</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Reefer.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Reefer" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Reefer</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Chemical Industry.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Chemical" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Chemical Industry</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Food.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Food" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Food</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Oil And Gas.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Oil" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Oil And Gas</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Renewable Energy.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Renewable" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Renewable Energy</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Construction.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Construction" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Construction</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Furniture.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Furniture" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Furniture</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Pharma & Healthcare.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Pharma" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Pharma & Healthcare</h3>
                <span>Industries</span>
              </div>
            </div>
            <div className="single-team-box col-lg-4">
              <div className="image">
                <img src="assets/img/Retail.jpg" alt="image" />
                <ul className="social">
                  <Link to="/Retail" className="default-btn">
                    Read More <span />
                  </Link>
                </ul>
              </div>
              <div className="content">
                <h3>Retail</h3>
                <span>Industries</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
