import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Contract Logistics</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Contract Logistics</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Blog Details Area */}
      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img src="assets/img/Contract Logistics.jpg" alt="image" />
                </div>
                <div className="article-content">
                  <div className="entry-meta"></div>
                  <h3>Contract Logistics</h3>
                  <p>
                    We enhance the entire supply chain by integrating all
                    processes, including storage, transportation, and
                    value-added services, and by employing comprehensive
                    logistics management tailored to specific markets.
                  </p>
                  <p>
                    Our contract logistics solutions serve as valuable guidance
                    and support for our clients' logistics activities. We
                    utilize infrastructure, technology, and information systems
                    that continually adapt to changing market requirements.
                  </p>
                  <p>
                    We develop customized solutions for various industries, with
                    a strong focus on warehouse services and specialized supply
                    chain solutions. From production to distribution at the
                    point of sale, we follow an end-to-end management approach
                    in collaboration with our clients.
                  </p>
                  <p>
                    Our commitment to sustainability, process excellence,
                    integration capabilities, customization, and integrity
                    remains at the service of our clients.
                  </p>
                  <p>
                    <strong>
                      INTEGRATED SERVICES ACROSS THE ENTIRE CONTRACT LOGISTICS
                      PROCESS
                    </strong>
                  </p>
                  <p>
                    In the contract logistics value chain, we encompass every
                    facet of inventory management, including services related to
                    the production line, storage, distribution, and inventory
                    management.
                  </p>
                  <p>
                    <strong>TRANSPORT</strong>
                  </p>
                  <p>
                    We operate a top-tier partner-led integrated distribution
                    network that handles the collection and delivery of products
                    from the manufacturing line to the point of sale.
                  </p>
                  <p>
                    <strong>STORAGE</strong>
                  </p>
                  <p>
                    We manage operations in both single-client and multi-client
                    settings, continuously adapting to the specific needs of our
                    clients' industries. This includes overseeing the management
                    of items requiring specific temperature conditions and
                    providing various value-added services.
                  </p>
                  <p>
                    <strong>INVENTORY HANDLING AND MANAGEMENT</strong>
                  </p>
                  <p>
                    We maintain constant oversight of sample management, cycle
                    counts, quality checks, and stock levels.
                  </p>
                  <p>
                    <strong>VALUE-ADDED SERVICES</strong>
                  </p>
                  <p>
                    Our services enhance our clients' supply chains and are
                    tailored to meet the unique requirements of each industry
                    and product type. These services encompass various reworks,
                    kitting, special handling, packaging management, labeling,
                    and assemblies, among other operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_categories">
                  <h3 className="widget-title">Other Services</h3>
                  <ul>
                    <li>
                      <Link to="/Freight">Freight Management </Link>
                    </li>

                    <li>
                      <Link to="/Supply">
                        International Supply Chain Management{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/Contract">Contract Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Project">Project Cargo Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Global">Global Trade Management </Link>
                    </li>

                    <li>
                      <Link to="/Custom">Custom Clearance </Link>
                    </li>
                  </ul>
                </section>
                <section className="widget widget_recent_comments">
                  <h3 className="widget-title">Cargo Managed</h3>
                  <ul>
                    <li>
                      <Link to="/Perishables">Perishables </Link>
                    </li>
                    <li>
                      <Link to="/General">General Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Dangerous">Dangerous Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Heavy">Heavy Lift </Link>
                    </li>
                    <li>
                      <Link to="/Trade">Trade Shows & Exhibitions </Link>
                    </li>
                  </ul>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Details Area */}
      <Footer />
    </>
  );
}
