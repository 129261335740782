import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>General Cargo</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>General Cargo</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Blog Details Area */}
      <section className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img src="assets/img/General Cargo.jpg" alt="image" />
                </div>
                <div className="article-content">
                  <div className="entry-meta"></div>
                  <h3>General Cargo</h3>
                  <p>
                    {companyname} specializes in the management of
                    third/cross-country logistics. This type of logistics
                    management is employed when the client is based in Singapore
                    or another country, but the goods or cargo must be collected
                    from a second origin country and transported to a third
                    country without passing through Singapore or the buyer's
                    country.
                  </p>
                  <p>
                    We provide a comprehensive suite of services, encompassing
                    pickup from the origin country, customs clearance, booking
                    and loading onto the aircraft, customs clearance, and final
                    delivery to the destination in the third country.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_categories">
                  <h3 className="widget-title">Other Services</h3>
                  <ul>
                    <li>
                      <Link to="/Freight">Freight Management </Link>
                    </li>

                    <li>
                      <Link to="/Supply">
                        International Supply Chain Management{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to="/Contract">Contract Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Project">Project Cargo Logistics </Link>
                    </li>

                    <li>
                      <Link to="/Global">Global Trade Management </Link>
                    </li>

                    <li>
                      <Link to="/Custom">Custom Clearance </Link>
                    </li>
                  </ul>
                </section>
                <section className="widget widget_recent_comments">
                  <h3 className="widget-title">Cargo Managed</h3>
                  <ul>
                    <li>
                      <Link to="/Perishables">Perishables </Link>
                    </li>
                    <li>
                      <Link to="/General">General Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Dangerous">Dangerous Cargo </Link>
                    </li>
                    <li>
                      <Link to="/Heavy">Heavy Lift </Link>
                    </li>
                    <li>
                      <Link to="/Trade">Trade Shows & Exhibitions </Link>
                    </li>
                  </ul>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Details Area */}
      <Footer />
    </>
  );
}
