import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Chemical Industry</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Chemical Industry</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Chemical Industry</h3>
                  <p>{companyname} offers specialized transportation and integrated logistics solutions tailored to the chemical industry. We consistently uphold the highest quality and service standards, customizing our approach for each subsector, whether it's fine chemicals or consumer goods.</p>

<p>Our dedicated chemicals team, well-versed in handling chemical products, ensures the security of your supply chain. They receive continuous training in dangerous goods management and transport regulations, covering:</p>

<ul>
  <li>Dangerous Goods</li>
  <li>Specialized equipment and transportation methods</li>
</ul>

                  
                  </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Chemical Industry.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </section>
      <Footer />
    </>
  );
}
