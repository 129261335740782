import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Why Choose Us</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Why Choose Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="about-area ptb-100 bg-f8f8f8">
        <div className="container">
         
          
        </div>
      </section>
      <section className="why-choose-us-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div
                className="why-choose-us-slides "
                style={{ background: 'url("../assets/img/banner.jpg")' }}
              >
                <div className="why-choose-us-image bg1">
                  <img src="assets/img/why-choose-img1.jpg" alt="image" />
                </div>
               
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="why-choose-us-content">
                <div className="content">
                  <div className="title">
                    <span className="sub-title">Why Us</span>
                    <h2>Why Choose Us</h2>
                    <p>
                      We strive to provide exceptional maritime services,
                      comprehensive logistics solutions, and state-of-the-art
                      port terminals. Our commitment to continuous improvement
                      and dedication to operating in a secure environment aim to
                      make us the preferred choice for our clients.
                    </p>
                  </div>
                  <ul className="features-list">
                    <li>
                      <span>GREAT SUPPORT</span>
                      Experience exceptional customer service with a dedicated
                      Personal Singapore Consultant, ensuring outstanding
                      support from our team.
                    </li>
                    <li>
                      <span>ONE STOP PROVIDER</span>
                      We serve as a comprehensive one-stop solution, offering a
                      wide array of services aimed at delivering top-tier
                      freight solutions.
                    </li>
                    <li>
                      <span>COMPETITIVE PRICING</span>
                      Benefit from competitive pricing as one of the largest
                      global freight forwarding companies, allowing us to pass
                      on cost savings to our valued customers.
                    </li>
                    <li>
                      <span>TRANSSHIPMENT</span>
                      Transshipment offers flexible and cost-effective shipping
                      routes, providing advantages to shippers (consignors).
                    </li>
                    <li>
                      <span>CONSOLIDATED FREIGHT</span>
                      Consolidation shipping services are ideal for businesses
                      or individuals with smaller cargo quantities, helping to
                      reduce costs by paying only for the space utilized.
                    </li>
                    <li>
                      <span>PICK AND PACK WAREHOUSING</span>
                      Pick and pack warehousing involves selecting products from
                      a warehouse based on customer orders and preparing them
                      for delivery as a crucial step in the shipping process.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area ptb-100 bg-f8f8f8">
        <div className="container">
         
          <div className="about-inner-area">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="about-text-box">
                  <h3>Our Mission</h3>
                  <p>
                    Our mission is to deliver superior maritime services,
                    streamline logistics processes, and operate modern port
                    terminals while ensuring the highest standards of safety and
                    security, ultimately becoming the go-to choice for our
                    valued clients.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3 col-sm-6">
                <div className="about-text-box">
                  <h3>Our Vision</h3>
                  <p>
                    Our vision is to establish ourselves as a market-leading
                    organization known for excellence in maritime services,
                    logistics solutions, and port operations on a global scale.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
