import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Food</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Food</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Food</h3>
                  <p>
                    We understand the immense responsibility that the food
                    industry bears in safeguarding the food safety of millions
                    worldwide.
                  </p>

                  <p>
                    Therefore, we consistently provide the necessary resources
                    for the proper preservation, handling, and distribution of
                    food products, all while maintaining the highest levels of
                    quality and customer service standards.
                  </p>

                  <p>
                    Our specialized services cater to the diverse needs of the
                    food industry, encompassing various product types, including
                    those requiring cold storage such as perishable and reefer
                    goods.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Food.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
