import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {

  return (
    <>
   <header className="header-area header-style-two">
          {/* Start Top Header */}
          <div className="top-header">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-12">
                  <ul className="top-header-nav">
                    <li><Link to="/Careers">Career</Link></li>
                    <li><Link to="/Getquote">Get Quote</Link></li>
                    <li><Link to="/Privacy">Privacy Policy</Link></li>
                    <li><Link to="/Terms">Terms</Link></li>
                  </ul>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="top-header-right-side">
                    <ul>
                      <li>
                        <div className="icon">
                          <i className="flaticon-email" />
                        </div>
                        <span>Drop us an email:</span>
                        <Link to="#">{companyemail}</Link>
                      </li>
                     
                      <li>
                        <Link to="/Track" className="default-btn">Track Shipment <span /></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Top Header */}
          {/* Start Navbar Area */}
          <div className="navbar-area">
            <div className="pearo-responsive-nav">
              <div className="container">
                <div className="pearo-responsive-menu">
                  <div className="logo">
                    <Link to="/">
                      <img src="assets/img/logo.png" alt="logo" style={{height:"70px"}}  />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="pearo-nav">
              <div className="container">
                <nav className="navbar navbar-expand-md navbar-light">
                  <Link className="navbar-brand" to="/">
                  <img src="assets/img/logo.png" alt="logo" style={{height:"70px"}} />
                  </Link>
                  <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                    <li className="nav-item"><Link to="/Home" className="nav-link">Home</Link></li>
                     
                      <li className="nav-item"><Link to="#" className="nav-link">About Company <i className="flaticon-down-arrow" /></Link>
                        <ul className="dropdown-menu">
                          <li className="nav-item"><Link to="/About" className="nav-link">About Us</Link></li>
                          <li className="nav-item"><Link to="/Whyus" className="nav-link">Why Choose Us</Link></li>
                          <li className="nav-item"><Link to="/Missionvision" className="nav-link">Company's Mission-Vision</Link></li>
                          <li className="nav-item"><Link to="/Careers" className="nav-link">Careers at {companyname} </Link></li>
                         
                        </ul>
                      </li>
                      <li className="nav-item"><Link to="#" className="nav-link">Freight Services <i className="flaticon-down-arrow" /></Link>
                        <ul className="dropdown-menu">
                        <li className="nav-item"><Link to="/Freight" className="nav-link">Freight Management </Link></li>
                        <li className="nav-item"><Link to="/Supply" className="nav-link">International Supply Chain Management </Link></li>
                        <li className="nav-item"><Link to="/Contract" className="nav-link">Contract Logistics </Link></li>
                        <li className="nav-item"><Link to="/Project" className="nav-link">Project Cargo Logistics </Link></li>
                        <li className="nav-item"><Link to="/Global" className="nav-link">Global Trade Management </Link></li>
                        <li className="nav-item"><Link to="/Custom" className="nav-link">Custom Clearance </Link></li>

                         
                        </ul>
                      </li>
                      <li className="nav-item"><Link to="#" className="nav-link">Cargo Managed<i className="flaticon-down-arrow" /></Link>
                        <ul className="dropdown-menu">
                        <li className="nav-item"><Link to="/Perishables" className="nav-link">Perishables </Link></li>
                        <li className="nav-item"><Link to="/General" className="nav-link">General Cargo </Link></li>
                        <li className="nav-item"><Link to="/Dangerous" className="nav-link">Dangerous Cargo </Link></li>
                        <li className="nav-item"><Link to="/Heavy" className="nav-link">Heavy Lift </Link></li>
                        <li className="nav-item"><Link to="/Trade" className="nav-link">Trade Shows & Exhibitions </Link></li>

                        </ul>
                      </li>
                      <li className="nav-item"><Link to="#" className="nav-link">Industries<i className="flaticon-down-arrow" /></Link>
                        <ul className="dropdown-menu">
                        <li className="nav-item"><Link to="/Automotive" className="nav-link">Automotive </Link></li>
                        <li className="nav-item"><Link to="/Fashion" className="nav-link">Fashion </Link></li>
                        <li className="nav-item"><Link to="/Mining" className="nav-link">Mining </Link></li>
                        <li className="nav-item"><Link to="/Reefer" className="nav-link">Reefer </Link></li>
                        <li className="nav-item"><Link to="/Chemical" className="nav-link">Chemical Industry </Link></li>
                        <li className="nav-item"><Link to="/Food" className="nav-link">Food </Link></li>
                        <li className="nav-item"><Link to="/Oil" className="nav-link">Oil And Gas </Link></li>
                        <li className="nav-item"><Link to="/Renewable" className="nav-link">Renewable Energy </Link></li>
                        <li className="nav-item"><Link to="/Construction" className="nav-link">Construction </Link></li>
                        <li className="nav-item"><Link to="/Furniture" className="nav-link">Furniture </Link></li>
                        <li className="nav-item"><Link to="/Pharma" className="nav-link">Pharma & Healthcare </Link></li>
                        <li className="nav-item"><Link to="/Retail" className="nav-link">Retail </Link></li>

                        </ul>
                      </li>
                      <li className="nav-item"><Link to="#" className="nav-link">Resources<i className="flaticon-down-arrow" /></Link>
                        <ul className="dropdown-menu">
                        <li className="nav-item"><Link to="/Incoterms" className="nav-link">Incoterms </Link></li>
                        <li className="nav-item"><Link to="/Container" className="nav-link">Container Dimensions </Link></li>
                        <li className="nav-item"><Link to="/Faqs" className="nav-link">FAQ's </Link></li>
                        <li className="nav-item"><Link to="/Glossary" className="nav-link">Glossary </Link></li>

                        <li className="nav-item"><Link to="/Privacy" className="nav-link">Privacy Policy </Link></li>
                        <li className="nav-item"><Link to="/Terms" className="nav-link">Terms & Conditions </Link></li>
                        <li className="nav-item"><Link to="/Getquote" className="nav-link">Request A Quote </Link></li>

                        </ul>
                      </li>
                      <li className="nav-item"><Link to="/Contact" className="nav-link">Contact</Link></li>
                    </ul>
                    <div className="others-option">
                     
                     
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          {/* End Navbar Area */}
        </header>

    </>
  );
};

export default Header;
