import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Fashion</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Fashion</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="insurance-details-area ptb-100">
        <div className="container">
          <div className="insurance-details-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="content">
                  <h3>Fashion</h3>
                  <p>
                    To enhance and bolster our clients' competitiveness within
                    the fashion industry, we deliver customized solutions
                    tailored to their needs.
                  </p>

                  <p>
                    With fashion product life cycles growing increasingly
                    shorter each year, we've undertaken a comprehensive overhaul
                    of our resources. This transformation aligns with the
                    industry's demand for swiftness, quality, adaptability, and
                    responsiveness to change.
                  </p>

                  <p>
                    From shipping to warehousing and a spectrum of value-added
                    services, we manage your entire supply chain. Our services
                    encompass shipment planning, execution, and an array of
                    value-added offerings such as samples, light assembly,
                    kitting, repackaging, and conversion tasks, ensuring you're
                    well-equipped to meet market demands.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image text-center">
                  <img src="assets/img/Fashion.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
