import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import jsPDF from "jspdf";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import axios from "axios";
import "jspdf-autotable";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [trackingNo, setTrackingNo] = useState("");
  const [items, setItems] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const generatePDF = () => {
    const doc = new jsPDF();

    const pageTitle = "rightmovetradelogistics.sg/Track";
    const textWidth = doc.getTextWidth(pageTitle);
    const pageWidth = doc.internal.pageSize.getWidth();
    const xPos = pageWidth - textWidth;

    const logoWidth = 35;
    const logoHeight = 23;

    doc.setFont("Arial", "", 8);

    const headers = [
      "Container No",
      "Container Type",
      "Vessel Voyage",
      "BL No",
      "Origin",
      "Destination",
      "Departure Time",
      "Arrival Time",
    ];
    const data = items.map((item) => [
      item.containerNo,
      item.equipmentType,
      item.vesselVoyage,
      item.blNo,
      item.portOfLoading,
      item.portOfDischarge,
      item.exTimeOfDeparture,
      item.exTimeArrival,
    ]);

    const tableOptions = {
      startY: 50, // Adjusted startY to accommodate the header content
      headStyles: {
        fillColor: [58, 173, 225],
        textColor: [255, 255, 255],
      },
      styles: { textColor: [0, 0, 0], fontSize: 10 },

      didDrawPage: function (data) {
        // Footer content
        const pageCount = doc.internal.getNumberOfPages();
        const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
        const formattedDate = new Date().toLocaleDateString();
        const formattedTime = new Date().toLocaleTimeString();

        const footerText = `Page ${currentPage}  | Date: ${formattedDate} | Time: ${formattedTime}`;
        const footerX = doc.internal.pageSize.getWidth() / 2;
        const footerY = doc.internal.pageSize.getHeight() - 10;
        doc.text(footerText, footerX, footerY, { align: "center" });
      },
    };

    // Header content
    doc.setFont("Arial", "B", 18);
    doc.text("RIGTHMOVE TRADING & LOGISTICS PTE. LTD SHIPMENT DETAILS", 105, 45, {
      align: "center",
    });

    // Logo
    doc.addImage("assets/img/logo.png", "PNG", 20, 7, logoWidth, logoHeight);

    // Title
    doc.setFont("Arial", "", 8);
    doc.setFontSize(10);
    doc.text(pageTitle, 150, 15);
    const imageCompressionOptions = {
      quality: 0.7, // Adjust this value to control image quality
    };
    // Generate the table using autoTable function with the defined options
    doc.autoTable(headers, data, tableOptions);

    doc.save("shipment-details.pdf");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submitType = e.target.name; // Get the form name (e.g., "submit1")
    axios
      .get(
        `https://sgserver.net/shipmenttracking/index.php?trackingNo=${trackingNo}&submitType=${submitType}`
      )
      .then((response) => {
        if (response.data.length > 0) {
          setItems(response.data);
          setNotFound(false);
        } else {
          setItems([]);
          setNotFound(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <Header />
      <div className="page-title-area page-title-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Track Shipment</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Track Shipment</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Start Contact Area */}
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              style={{ backgroundImage: "url('')" }}
            >
              <img className="img-fluid" src="assets/img/track.png"></img>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="section-title">
                <span className="sub-title">Track Shipment</span>
                <h2>Track Your Shipment</h2>
                <p>
                  Don't miss out on the opportunity to track your shipment
                  effortlessly. Take a moment to fill out our user-friendly
                  tracking form and stay in the loop.
                </p>
              </div>
              <div className="contact-form">
                <form onSubmit={handleSubmit} name="rightmovetradelogistics">
                  <div className="row g-3">
                    <div className=" col-lg-6">
                      <input
                        type="text"
                        value={trackingNo}
                        onChange={(e) => setTrackingNo(e.target.value)}
                        placeholder="Enter BL No. or Container No."
                        className="form-control w-100 py-3"
                      />
                      <span />
                    </div>

                    <div className=" col-lg-6  text-lg-center">
                      <button
                        name="submit"
                        className="default-btn "
                        type="submit"
                      >
                        Track Shipment
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-map">
          <img src="assets/img/bg-map.png" alt="image" />
        </div>
      </section>
      <div className="container ">
        {" "}
        {items.length > 0 && (
          <div className="table-wrapper">
            <table style={{ width: " -webkit-fill-available" }}>
              <thead style={{ border: "1px solid #3aade1" }}>
                <tr>
                  <th scope="col" className="d-flex">
                    <img
                      src="assets/img/worldwide-shipping.png"
                      style={{ height: "", maxHeight: "25px" }}
                    />
                    Container No
                  </th>

                  <th scope="col">
                    {" "}
                    <img
                      src="assets/img/container.png"
                      style={{ height: "", maxHeight: "25px" }}
                    />{" "}
                    Type
                  </th>
                  <th scope="col">
                    <img
                      src="assets/img/freight.png"
                      style={{ height: "", maxHeight: "25px" }}
                    />{" "}
                    Port Of Loading
                  </th>
                  <th scope="col">
                    <img
                      src="assets/img/placeholder.png"
                      style={{ height: "", maxHeight: "25px" }}
                    />{" "}
                    Port Of Destination
                  </th>
                  <th scope="col">
                    <img
                      src="assets/img/airplane.png"
                      style={{ height: "", maxHeight: "25px" }}
                    />{" "}
                    Estimated Departure Time
                  </th>
                  <th scope="col">
                    <img
                      src="assets/img/arrival-time.png"
                      style={{ height: "", maxHeight: "25px" }}
                    />{" "}
                    Estimated Arrival Time
                  </th>
                  <th scope="col"> Vessel Voyage</th>
                  <th scope="col">Details</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {notFound ? (
                  <tr>
                    <td colSpan="3">No items found with the given name.</td>
                  </tr>
                ) : (
                  items.map((item) => (
                    <tr key={item.id}>
                      <td data-label="Container No">{item.containerNo}</td>

                      <td data-label="Type">{item.equipmentType}</td>
                      <td data-label="Port Of Loading">{item.portOfLoading}</td>
                      <td data-label="Port Of Destination">
                        {item.portOfDischarge}
                      </td>
                      <td data-label="Estimated Departure Time">
                        {item.exTimeOfDeparture}
                      </td>
                      <td data-label="Estimated Arrival Time">
                        {item.exTimeArrival}
                      </td>
                      <td data-label="Vessel Voyage">{item.vesselVoyage}</td>

                      <td>
                        <Link
                          to={{
                            pathname: `/Trace`,
                            search: `id=${item.blNo}&contid=${item.containerNo}`,
                          }}
                          className="default-btn m-2 "
                          style={{ background: "#3aade1", padding: "8px" }}
                        >
                          <img
                            src="assets/img/search.png"
                            style={{ height: "", maxHeight: "25px" }}
                          />
                        </Link>
                      </td>
                      <td>
                        <Link
                          onClick={generatePDF}
                          className="default-btn m-2 "
                          style={{ background: "#012f8f" }}
                        >
                          Download
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
        {items.length === 0 && notFound && (
          <p>
            No Air/Sea Way BL available. Please provide the valid Container/BL
            no. to get the results
          </p>
        )}
      </div>

      <Footer />
    </>
  );
}
